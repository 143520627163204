import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, CircularProgress, Alert } from '@mui/material';
import { useAddRequirementGroupMutation } from '../../../services/requirementGroups';

interface IFormInput {
  name: string; // The name of the requirement group
}

const AddRequirementGroupForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const [addRequirementGroup, { isLoading, isSuccess, isError, error }] =
    useAddRequirementGroupMutation();

  const onSubmit = async (data: IFormInput) => {
    await addRequirementGroup({
      name: data.name,
    });
    reset(); // Reset the form fields after submission
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display='flex' flexDirection='column' gap={2} maxWidth={400}>
        <TextField
          {...register('name', {
            required: 'Requirement group name is required',
          })}
          label='Requirement Group Name'
          variant='outlined'
          error={!!errors.name}
          helperText={errors.name?.message}
          disabled={isLoading}
        />
        <Button type='submit' variant='contained' disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Add Requirement Group'}
        </Button>
        {isSuccess && (
          <Alert severity='success'>
            Requirement group added successfully!
          </Alert>
        )}
        {isError && (
          <Alert severity='error'>
            Error adding requirement group: {error?.toString()}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default AddRequirementGroupForm;
