import { Button, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getExerciseGroupData,
  clearExerciseGroupData,
} from "../../../utils/indexedDB";
import colors from "../../../config/colors";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TypedUseMutationResult } from "@reduxjs/toolkit/query/react";
import {
  MutationDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Example } from "../../../interfaces";

export interface NoExampleProps {
  exerciseId: string;
  triggerRandomExample: (args: {
    exerciseId: string;
    doneExamples: string[];
  }) => void;
}

const NoExample = ({ exerciseId, triggerRandomExample }: NoExampleProps) => {
  const [doneExamples, setDoneExamples] = useState<string[]>([]);

  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  useEffect(() => {
    const fetchDoneExamples = async () => {
      const groupData = await getExerciseGroupData(exerciseId);
      const mergedExamples = [
        ...(groupData?.completed || []),
        ...(groupData?.skipped || []),
      ];

      setDoneExamples(mergedExamples);
    };

    fetchDoneExamples();
  }, [exerciseId]);

  const handleReloadExamples = async () => {
    // Clear the exercise group data in IndexedDB
    await clearExerciseGroupData(exerciseId);
    triggerRandomExample({
      exerciseId,
      doneExamples: [],
    });

    // After clearing, you can either reset the local state or trigger a refresh
    setDoneExamples([]); // Reset the state to reflect that there are no done examples

    // You might want to trigger a re-fetch or navigate to a different view after clearing
  };

  if (doneExamples.length > 0) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h6" component="p">
          Rozwiązałeś wszystkie dostępne zadania
        </Typography>
        <Button
          onClick={handleReloadExamples}
          variant="contained"
          sx={{
            background: colors.primary.dark,
            padding: isSmallScreen ? "18px 24px" : "18px 18px",
            width: "100%",
            minWidth: isSmallScreen ? "160px" : "110px",
            maxWidth: "200px",
            borderRadius: "4px",
            mt: 4,
            "&:hover": {
              background: colors.primary.dark,
              filter: "brightness(90%)",
            },
          }}
          startIcon={<RefreshIcon />}
        >
          <Typography color={colors.contrast} variant="button">
            Odśwież
          </Typography>
        </Button>
      </Stack>
    );
  }

  return <Typography>Brak dostępnych zadań</Typography>;
};

export default NoExample;
