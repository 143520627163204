import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateExerciseGroupDto, ExerciseGroup } from "../interfaces";

export const exercisesApi = createApi({
  reducerPath: "exercisesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Exercise"],
  endpoints: (builder) => ({
    getExercises: builder.query<
      ExerciseGroup[],
      { topicId?: string; exerciseName?: string | null; leavingExam?: string }
    >({
      query: ({ topicId, exerciseName, leavingExam }) => {
        let queryString = `/exercise-groups?`;
        if (topicId) {
          queryString += `&topicId=${topicId}`;
        }
        if (exerciseName) {
          queryString += `&exerciseName=${encodeURIComponent(exerciseName)}`;
        }

        if (leavingExam) {
          queryString += `&leavingExam=${leavingExam}`;
        }
        return queryString;
      },
      providesTags: ["Exercise"],
    }),
    addExercise: builder.mutation<
      CreateExerciseGroupDto,
      Partial<CreateExerciseGroupDto>
    >({
      query: (newExercise) => ({
        url: "/exercise-groups",
        method: "POST",
        body: {
          name: newExercise.name,
          exerciseTypeId: newExercise.exerciseTypeId,
          isLeavingExamGroup: newExercise.isLeavingExamGroup,
          description: newExercise.description,
        },
      }),
      invalidatesTags: ["Exercise"],
    }),
    deleteExerciseGroup: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query: (id) => ({
        url: `/exercise-groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Exercise"],
    }),
    editExercise: builder.mutation<
      ExerciseGroup,
      { id: string; data: Partial<ExerciseGroup> }
    >({
      query: ({ id, data }) => ({
        url: `/exercise-groups/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Exercise"],
    }),
  }),
});

export const {
  useGetExercisesQuery,
  useLazyGetExercisesQuery,
  useAddExerciseMutation,
  useEditExerciseMutation,
  useDeleteExerciseGroupMutation,
} = exercisesApi;
