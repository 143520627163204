import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Requirement } from "../interfaces"; // Adjust the import path as needed

export const requirementsApi = createApi({
  reducerPath: "requirementsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Requirement"],
  endpoints: (builder) => ({
    getRequirements: builder.query<Requirement[], void>({
      query: () => "/requirements", // Adjust the API endpoint path for requirements
      providesTags: ["Requirement"],
    }),
    addRequirement: builder.mutation<Requirement, Partial<Requirement>>({
      query: (newRequirement) => ({
        url: "/requirements", // Adjust the API endpoint path for adding requirements
        method: "POST",
        body: newRequirement,
      }),
      invalidatesTags: ["Requirement"],
    }),
    editRequirement: builder.mutation<
      Requirement,
      { id: number; data: Partial<Requirement> }
    >({
      query: ({ id, data }) => ({
        url: `/requirements/${id}`, // Adjust the API endpoint path for editing requirements
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Requirement"],
    }),
    deleteRequirement: builder.mutation<
      { success: boolean; id: number },
      number
    >({
      query: (id) => ({
        url: `/requirements/${id}`, // Adjust the API endpoint path for deleting requirements
        method: "DELETE",
      }),
      invalidatesTags: ["Requirement"],
    }),
  }),
});

export const {
  useGetRequirementsQuery,
  useAddRequirementMutation,
  useEditRequirementMutation,
  useDeleteRequirementMutation, // Include this if implementing deletion functionality
} = requirementsApi;
