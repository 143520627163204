const typography = {
  typography: {
    fontSize: 12,
    fontFamily: "Inter, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/h1": {
    fontSize: 60,
    fontFamily: "Oswald, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "-1.5px",
    lineHeight: "116.70000553131104%",
  },
  "typography/h2": {
    fontSize: 60,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 300,
    letterSpacing: "-0.5px",
    lineHeight: "120.00000476837158%",
  },
  "typography/h3": {
    fontSize: 48,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "116.70000553131104%",
  },
  "typography/h4": {
    fontSize: 34,
    fontFamily: "Oswald, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "123.50000143051147%",
  },
  "typography/h5": {
    fontSize: 24,
    fontFamily: "Oswald, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "133.39999914169312%",
  },
  "typography/h6": {
    fontSize: 20,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "160.0000023841858%",
  },
  "typography/body1": {
    fontSize: 14,
    fontFamily: "EB Garamond, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "typography/body2": {
    fontSize: 16,
    fontFamily: "EB Garamond, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "142.99999475479126%",
  },
  "typography/subtitle1": {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "175%",
  },
  "typography/subtitle2": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "157.00000524520874%",
  },
  "typography/overline": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "1px",
    lineHeight: "266.00000858306885%",
  },
  "typography/caption": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "alert/title": {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "alert/description": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "142.99999475479126%",
  },
  "avatar/initialslg": {
    fontSize: 20,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "avatar/initialssm": {
    fontSize: 10,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "10px",
  },
  "avatar/initialsmd": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0px",
    lineHeight: "12px",
  },
  "badge/label": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "bottomnavigation/activelabel": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "button/large": {
    fontSize: 15,
    fontFamily: "Oswald, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "26px",
  },
  "button/medium": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.6000000238418579px",
    lineHeight: "24px",
  },
  "button/small": {
    fontSize: 13,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "22px",
  },
  "chip/label": {
    fontSize: 13,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.1599999964237213px",
    lineHeight: "18px",
  },
  "datepicker/currentmonth": {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "input/label": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "12px",
  },
  "input/value": {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "24px",
  },
  "input/helper": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "list/subheader": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "48px",
  },
  "menu/itemdefault": {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "menu/itemdense": {
    fontSize: 14,
    fontFamily: "EB Garamound, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "1px",
    lineHeight: "24px",
  },
  "table/header": {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "24px",
  },
  "tooltip/label": {
    fontSize: 10,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0px",
    lineHeight: "14px",
  },
  "datagrid/aggregationcolumnheaderlabel": {
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "12px",
  },
  "_library/heading": {
    fontSize: 64,
    fontFamily: "Roboto Mono, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "-1.5px",
    lineHeight: "116.70000553131104%",
  },
};

export default typography;
