import React from "react";
import { useAddTopicMutation } from "../../../services/topics"; // Adjust the import path as needed
import { useForm } from "react-hook-form";
import { TextField, Button, Box, CircularProgress, Alert } from "@mui/material";

interface IFormInput {
  topicName: string;
  description?: string;
}

const AddTopicForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const [addTopic, { isLoading, isSuccess, isError, error }] =
    useAddTopicMutation();

  const onSubmit = async (data: IFormInput) => {
    await addTopic({ name: data.topicName, description: data.description }); // Ensure your backend expects this payload structure
    reset(); // Reset the form fields after submission
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" flexDirection="column" gap={2} maxWidth={400}>
        <TextField
          {...register("topicName", {
            required: "Topic name is required",
          })}
          label="Nazwa tematu"
          variant="outlined"
          error={!!errors.topicName}
          helperText={errors.topicName?.message}
          disabled={isLoading}
        />
        <TextField
          {...register("description")}
          label="Opis tematu"
          variant="outlined"
          error={!!errors.topicName}
          helperText={errors.topicName?.message}
          disabled={isLoading}
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Dodaj temat"}
        </Button>
        {isSuccess && <Alert severity="success">Temat dodano pomyślnie</Alert>}
        {isError && (
          <Alert severity="error">
            Błąd w dodawaniu tematu: {error?.toString()}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default AddTopicForm;
