import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";

interface PhotoOptions {
  topAdjust?: number | string;
  maxWidth?: number | string;
}

interface FeatureCardProps {
  photo: string;
  title: string;
  description: string;
  photoOptions?: PhotoOptions;
}

const FeatureCard = ({
  photo,
  title,
  description,
  photoOptions,
}: FeatureCardProps) => {
  return (
    <Grid
      item
      xs={12}
      lg={3}
      xl={2}
      display="flex"
      flexDirection="column"
      mx={{ xs: "10px", lg: 0 }}
    >
      <Typography variant="h5" component="h4" textAlign="center">
        {title}
      </Typography>
      <Card
        sx={{
          padding: "24px 16px",
          boxShadow: "0px 2px 10px 0px rgba(66, 68, 90, 0.25)",
          mt: 2,
          height: { xs: "200px", md: "200px" },
        }}
      >
        <Box height="100%" width="auto" display="flex" justifyContent="center">
          <CardMedia
            component="img"
            image={photo}
            height="100%"
            width="auto"
            sx={{
              objectFit: "contain",
              maxWidth: photoOptions?.maxWidth || "initial",
            }}
          />
        </Box>
      </Card>
      <Typography
        variant="body1"
        fontSize="18px"
        component="h6"
        mt={2}
        textAlign="center"
        height={100}
      >
        {description}
      </Typography>
    </Grid>
  );
};

export default FeatureCard;
