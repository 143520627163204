import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Example } from "../interfaces";

export const examplesApi = createApi({
  reducerPath: "examplesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL, // Ensure this points to your actual API base URL
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Example"],
  endpoints: (builder) => ({
    getExamples: builder.query<Example[], number | undefined>({
      query: (exerciseGroupId) =>
        `/examples?exerciseGroupId=${exerciseGroupId}`,
      providesTags: ["Example"],
    }),
    getRandomExample: builder.mutation<
      { example: Example; reset: boolean },
      { exerciseId: string; doneExamples: string[] }
    >({
      query: ({ exerciseId, doneExamples }) => ({
        url: `/examples/random/${exerciseId}`,
        method: "POST",
        body: {
          exerciseId,
          doneExamples, // Passing as query parameters
        },
      }),
      invalidatesTags: ["Example"],
    }),
    addExample: builder.mutation<Example, FormData>({
      query: (newExample) => ({
        url: "/examples",
        method: "POST",
        body: newExample,
      }),
      invalidatesTags: ["Example"],
    }),
    editExample: builder.mutation<Example, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/examples/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Example"],
    }),
    deleteExample: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/examples/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Example"],
    }),
  }),
});

export const {
  useGetExamplesQuery,
  useGetRandomExampleMutation,
  useAddExampleMutation,
  useEditExampleMutation,
  useDeleteExampleMutation,
} = examplesApi;
