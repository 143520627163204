import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Stack,
  IconButton,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  CardMedia,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar/Navbar";
import { ArrowBack } from "@mui/icons-material";
import { useLazyGetExercisesQuery } from "../../services/exercises";
import AnswerChoice from "../../components/AnswerChoice/AnswerChoice";
import { EexerciseType } from "../../enums";
import colors from "../../config/colors";
import { Helmet } from "react-helmet";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import CheckCircleIcon from "@mui/icons-material/CheckBoxOutlined";
import { BlockMath, InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
import "./styles.scss";
import {
  addCompletedExercise,
  addSkippedExercise,
  getExerciseGroupData,
} from "../../utils/indexedDB"; // Import the updated indexedDB utility functions
import { useGetRandomExampleMutation } from "../../services/examples";
import NoExample from "./NoExample/NoExample";

export default function ExamplePage() {
  const [searchedPhrase, setSearchedPhrase] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const exerciseId = location.state?.exerciseId;
  const exerciseType = location.state?.exerciseType;
  const exerciseName = location.state?.exerciseName;
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean | null>(null);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [answerChoiceKey, setAnswerChoiceKey] = useState(0);
  const [exerciseGroupData, setExerciseGroupData] = useState<any>(undefined);

  const [triggerExercises, { data: exercises, isLoading, error }] =
    useLazyGetExercisesQuery();
  const [
    triggerRandomExample,
    {
      data: randomExample,
      isLoading: isRandomExampleLoading,
      error: randomExampleError,
    },
  ] = useGetRandomExampleMutation();

  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  const loadExerciseGroupDataAndTriggerRandomExample = async () => {
    if (exerciseId) {
      const groupData = await getExerciseGroupData(exerciseId);
      const doneExamples = [
        ...(groupData?.completed || []),
        ...(groupData?.skipped || []),
      ];
      triggerRandomExample({ exerciseId, doneExamples });
    }
  };

  useEffect(() => {
    if (exerciseId !== undefined) {
      loadExerciseGroupDataAndTriggerRandomExample();
    }
  }, [exerciseId, searchedPhrase, triggerExercises]);

  useEffect(() => {
    const fetchData = async () => {
      if (randomExample?.example?.exerciseGroup?.id) {
        const data = await getExerciseGroupData(
          randomExample.example.exerciseGroup.id
        );
        setExerciseGroupData(data);
      }
    };

    fetchData();
  }, [randomExample]);

  const handleCheckAnswers = async () => {
    setIsAnswerCorrect(null);
    const exerciseGroupId = randomExample?.example?.exerciseGroup?.id;
    const exerciseExampleId = randomExample?.example?.id;

    if (exerciseType.id === EexerciseType.short && Array.isArray(userAnswers)) {
      const isCorrect =
        userAnswers.length === randomExample?.example?.correctAnswers?.length &&
        userAnswers.every(
          (answer, index) =>
            answer === randomExample?.example?.correctAnswers?.[index]
        );
      setIsAnswerCorrect(isCorrect);
      if (isCorrect && exerciseGroupId && exerciseExampleId) {
        await addCompletedExercise(exerciseGroupId, exerciseExampleId);
      }
    } else if (
      Array.isArray(userAnswers) &&
      userAnswers.length === 1 &&
      randomExample?.example?.correctAnswers
    ) {
      const isCorrect =
        userAnswers[0] === randomExample?.example?.correctAnswers[0];
      setIsAnswerCorrect(isCorrect);
      if (isCorrect && exerciseGroupId && exerciseExampleId) {
        await addCompletedExercise(exerciseGroupId, exerciseExampleId);
      }
    }
  };

  const handleNext = async () => {
    const exerciseGroupId = randomExample?.example?.exerciseGroup?.id;
    const exerciseExampleId = randomExample?.example?.id;

    if (exerciseGroupId && exerciseExampleId && !isAnswerCorrect) {
      return;
    }

    setUserAnswers([]);
    loadExerciseGroupDataAndTriggerRandomExample();
    setIsAnswerCorrect(null);
    setAnswerChoiceKey((prevKey) => prevKey + 1);
  };

  const handleSkip = async () => {
    const exerciseGroupId = randomExample?.example?.exerciseGroup?.id;
    const exerciseExampleId = randomExample?.example?.id;
    if (exerciseGroupId && exerciseExampleId) {
      await addCompletedExercise(exerciseGroupId, exerciseExampleId);
    }
    setUserAnswers([]);
    loadExerciseGroupDataAndTriggerRandomExample();
    setIsAnswerCorrect(null);
    setAnswerChoiceKey((prevKey) => prevKey + 1);
  };

  const handleImageClick = () => {
    setIsLightboxOpen(true);
  };

  return (
    <Box>
      <Helmet>
        <title>{"Examly | Zadanie"}</title>
        <meta
          name="description"
          content="Rozwiązuj zadania - niezależnie od tego czy należy wybrać poprawną odpowiedź spośród podanych czy podać własne rozwiązanie. Z Examly zdobędziesz biegłość, dzięki której matematyka nie będzie stanowić przeszkód"
        />
        <link
          rel="canonical"
          href={`https://examly.eu/rozwiaz/${exerciseName}`}
        />
      </Helmet>
      <Navbar />
      <Container sx={{ textAlign: "center", mt: 10, mb: 10 }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={isMediumScreen ? "initial" : "center"}
          mt={4}
          position="relative"
          px={6}
        >
          <Typography
            ml={2}
            variant="h4"
            color={colors.text.primary}
            textAlign="left"
            display="flex"
            alignItems="center"
            position="relative"
          >
            {exerciseName && "Zadanie typu: "}
            {exerciseName}
            <IconButton
              aria-label="go back"
              onClick={() => navigate(-1)}
              size="large"
              sx={{
                position: "absolute",
                left: "-58px",
              }}
            >
              <ArrowBack sx={{ stroke: colors.border, fontSize: "28px" }} />
            </IconButton>
          </Typography>
        </Stack>
        {randomExampleError && (
          <Typography>Błąd przy ładowaniu przykładu</Typography>
        )}
        {randomExample && (
          <Card
            sx={{
              padding: "24px 16px",
              boxShadow: "0px 2px 10px 0px rgba(66, 68, 90, 0.25)",
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mt: 6,
            }}
          >
            {randomExample.example ? (
              <Stack
                sx={{
                  minHeight: "200px",
                }}
              >
                <CardHeader
                  title={
                    randomExample.example?.question.includes("\\") ? (
                      <Typography variant="body2" fontSize="16px" ml={2}>
                        <BlockMath>{randomExample.example?.question}</BlockMath>
                      </Typography>
                    ) : (
                      <Typography variant="h6" ml={2} lineHeight="143%">
                        {randomExample.example?.question}
                      </Typography>
                    )
                  }
                  sx={{ textAlign: "left", padding: 0, paddingRight: 3 }}
                  action={
                    randomExample?.example?.isLeavingExamExample && (
                      <Box display="flex" alignItems="center">
                        <CheckCircleIcon
                          sx={{
                            color: colors.primary.main,
                            mr: 0.5,
                            fontSize: 40,
                          }}
                        />
                        <Typography
                          variant="h6"
                          fontSize="18px"
                          color={colors.text.primary}
                        >
                          Zadanie maturalne
                        </Typography>
                      </Box>
                    )
                  }
                />
                <CardContent>
                  {randomExample.example.photos &&
                    randomExample.example.photos.length > 0 && (
                      <>
                        <CardMedia
                          component="img"
                          image={
                            process.env.REACT_APP_API_URL +
                            randomExample.example.photos[0].path
                          }
                          style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "700px",
                            aspectRatio: "16:9",
                            cursor: "pointer",
                          }}
                          onClick={handleImageClick}
                        />
                        <Lightbox
                          open={isLightboxOpen}
                          close={() => setIsLightboxOpen(false)}
                          slides={[
                            {
                              src:
                                process.env.REACT_APP_API_URL +
                                randomExample.example.photos[0].path,
                            },
                          ]}
                        />
                      </>
                    )}
                  <AnswerChoice
                    key={randomExample?.example?.id + answerChoiceKey}
                    answers={randomExample?.example?.answers}
                    exerciseType={exerciseType}
                    setUserAnswers={setUserAnswers}
                    userAnswers={userAnswers}
                  />
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between", gap: 4 }}>
                  {isAnswerCorrect !== null ? (
                    <Typography variant="body1">
                      {isAnswerCorrect
                        ? "Poprawna odpowiedź!"
                        : "Odpowiedź niepoprawna, spróbuj jeszcze raz"}
                    </Typography>
                  ) : (
                    <Box />
                  )}
                  <Stack
                    flexDirection={isSmallScreen ? "row" : "column"}
                    gap={4}
                  >
                    <Button
                      onClick={
                        isAnswerCorrect ? handleNext : handleCheckAnswers
                      }
                      variant="contained"
                      sx={{
                        background: colors.primary.main,
                        padding: isSmallScreen ? "18px 24px" : "18px 12px",
                        width: "100%",
                        minWidth: isSmallScreen ? "160px" : "110px",
                        maxWidth: "200px",
                        borderRadius: "4px",
                        "&:hover": {
                          background: colors.primary.main,
                          filter: "brightness(90%)",
                        },
                      }}
                    >
                      {isAnswerCorrect ? "Przejdź dalej" : "Sprawdź"}
                    </Button>
                    <Button
                      onClick={handleSkip}
                      variant="contained"
                      sx={{
                        background: colors.primary.dark,
                        padding: isSmallScreen ? "18px 24px" : "18px 18px",
                        width: "100%",
                        minWidth: isSmallScreen ? "160px" : "110px",
                        maxWidth: "200px",
                        borderRadius: "4px",
                        "&:hover": {
                          background: colors.primary.dark,
                          filter: "brightness(90%)",
                        },
                      }}
                    >
                      <Typography color={colors.contrast} variant="button">
                        Pomiń
                      </Typography>
                    </Button>
                  </Stack>
                </CardActions>
              </Stack>
            ) : (
              <NoExample
                exerciseId={exerciseId}
                triggerRandomExample={triggerRandomExample}
              />
            )}
          </Card>
        )}
      </Container>
    </Box>
  );
}
