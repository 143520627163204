import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useRegisterUserMutation } from "../../services/auth";
import "./styles.scss";
import Navbar from "../../components/common/Navbar/Navbar";
import { Helmet } from "react-helmet";

export default function RegisterPage() {
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      await registerUser({ email, password }).unwrap();
      alert("Registration successful");
    } catch (error) {
      alert("Registration failed");
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Examly"}</title>
      </Helmet>
      <Navbar />
      <Container maxWidth="sm" className="register-page">
        <Box className="register-form" boxShadow={3} padding={3}>
          <Typography variant="h4" gutterBottom>
            Register
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="submit-button"
              type="submit"
              disabled={isLoading}
            >
              Register
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
}
