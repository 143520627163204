function normalizeUrlName(name: string) {
  // Convert the name to lowercase and remove Polish characters
  const normalized = name.toLowerCase().replace(/[ąćęłńóśźż]/g, (c) => {
    switch (c) {
      case "ą":
        return "a";
      case "ć":
        return "c";
      case "ę":
        return "e";
      case "ł":
        return "l";
      case "ń":
        return "n";
      case "ó":
        return "o";
      case "ś":
        return "s";
      case "ź":
        return "z";
      case "ż":
        return "z";
      default:
        return c;
    }
  });

  // Replace spaces with hyphens
  return normalized.replace(/\s+/g, "-");
}

export default normalizeUrlName;
