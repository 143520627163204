import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Paper,
  Box,
  IconButton,
  Collapse,
  ListItem,
  ListItemText,
  List,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { green } from "@mui/material/colors";
import {
  useGetExamplesQuery,
  useEditExampleMutation,
  useDeleteExampleMutation,
} from "../../../services/examples";
import AddExampleForm from "./AddExampleForm";
import AddExerciseGroupForm from "./AddExerciseForm";
import EditExampleForm from "./EditExampleForm";
import {
  Example,
  ExerciseGroup,
  Resource,
  UpdateExampleDto,
} from "../../../interfaces"; // Adjust this import based on your actual file structure
import {
  useDeleteExerciseGroupMutation,
  useEditExerciseMutation,
  useGetExercisesQuery,
} from "../../../services/exercises";
import EditExerciseGroupForm from "./EditExerciseGroupForm";

const Exercises = () => {
  const {
    data: exercises,
    error: exercisesError,
    isLoading: isLoadingExercises,
  } = useGetExercisesQuery({});
  const { data: allExamples, isLoading: isLoadingExamples } =
    useGetExamplesQuery(undefined, { skip: false });

  const [isAddExampleOpen, setIsAddExampleOpen] = useState<boolean>(false);
  const [selectedExerciseGroup, setSelectedExerciseGroup] =
    useState<ExerciseGroup | null>(null);
  const [currentExample, setCurrentExample] = useState<Example | null>(null);
  const [isEditExampleOpen, setIsEditExampleOpen] = useState<boolean>(false);
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const [editExample] = useEditExampleMutation();
  const [deleteExample] = useDeleteExampleMutation();

  const handleOpenAddExampleForm = (exerciseGroup: ExerciseGroup) => {
    setSelectedExerciseGroup(exerciseGroup);
    setIsAddExampleOpen(true);
  };

  const [deleteExerciseGroup] = useDeleteExerciseGroupMutation();

  const handleRemoveGroup = async (groupId: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this group?"
    );
    if (confirmed) {
      try {
        await deleteExerciseGroup(groupId).unwrap();
      } catch (error) {
        console.error("Error deleting group:", error);
      }
    }
  };

  const [selectedGroupForEdit, setSelectedGroupForEdit] =
    useState<ExerciseGroup | null>(null);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState<boolean>(false);

  const handleOpenEditGroupForm = (group: ExerciseGroup) => {
    setSelectedGroupForEdit(group);
    setIsEditGroupOpen(true);
  };

  const [editExerciseGroup] = useEditExerciseMutation();

  const handleSaveEditedGroup = async (updatedGroup: ExerciseGroup) => {
    const payload = {
      name: updatedGroup.name,
      exerciseTypeId: updatedGroup.exerciseType.id || null,
      isLeavingExamGroup: updatedGroup.isLeavingExamGroup,
      description: updatedGroup.description,
    };

    try {
      await editExerciseGroup({
        id: updatedGroup.id,
        data: payload,
      }).unwrap();
      setIsEditGroupOpen(false);
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  const handleUpdateExample = (example: Example, exercise: ExerciseGroup) => {
    // Assuming `exerciseGroupId` and `resourceIds` can be derived or are already part of `Example`
    // Just for demonstration; you'll need to adjust according to your actual data structure
    const resourceIds: (Resource | string)[] = [];
    example.requirements.forEach((requirement) => {
      resourceIds.push(requirement.id);
    });

    const updateExampleDto: Example = {
      id: example.id,
      question: example.question,
      answers: example.answers,
      correctAnswers: example.correctAnswers || [],
      attachment: example.attachment,
      exerciseGroup: example.exerciseGroup,
      requirements: example.requirements,
      isLeavingExamExample: example.isLeavingExamExample,
    };

    setCurrentExample(updateExampleDto);
    setSelectedExerciseGroup(exercise);
    setIsEditExampleOpen(true);
  };

  const handleCloseEditForm = () => {
    setIsEditExampleOpen(false);
    setCurrentExample(null);
  };

  const handleSaveEditedExample = async (
    id: string,
    updatedExample: FormData
  ): Promise<void> => {
    if (!id) return; // Early return if no ID

    try {
      await editExample({
        id,
        data: updatedExample,
      }).unwrap();
      handleCloseEditForm();
    } catch (error) {
      console.error("Error updating example:", error);
    }
  };

  const handleRemoveExample = async (exampleId: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this example?"
    );
    if (confirmed) {
      try {
        await deleteExample(exampleId).unwrap();
      } catch (error) {
        console.error("Error deleting example:", error);
      }
    }
  };

  const toggleGroupExpansion = (groupId: string) => {
    setExpandedGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  if (isLoadingExercises || isLoadingExamples) {
    return <CircularProgress />;
  }

  if (exercisesError) {
    return (
      <Typography color="error">
        Error occurred: {exercisesError.toString()}
      </Typography>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Zadania i grupy zadań
      </Typography>
      <Paper sx={{ margin: "16px 0", padding: "8px" }}>
        {exercises?.map((exercise: ExerciseGroup) => (
          <Box key={exercise.id} sx={{ marginBottom: "16px" }}>
            <ListItem
              divider
              onClick={() => toggleGroupExpansion(exercise.id)}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "background.paper" },
              }}
            >
              <ListItemText
                primary={exercise.name}
                secondary={`Typ: ${exercise.exerciseType ? exercise.exerciseType.name : "Nieznany"}`}
              />
              <ListItemText primary={exercise.description} />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent ListItem onClick from being called
                    handleOpenEditGroupForm(exercise);
                  }}
                  color="primary"
                  sx={{ marginRight: "8px" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent ListItem onClick from being called
                    handleRemoveGroup(exercise.id);
                  }}
                  color="error"
                  sx={{ marginRight: "8px" }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent further propagation to avoid toggling expansion
                    handleOpenAddExampleForm(exercise);
                  }}
                  color="success"
                  sx={{ marginRight: "8px" }}
                >
                  <AddIcon />
                </IconButton>
                {expandedGroups.includes(exercise.id) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Box>
            </ListItem>
            <Collapse
              in={expandedGroups.includes(exercise.id)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {allExamples
                  ?.filter(
                    (example) => example.exerciseGroup?.id === exercise.id
                  )
                  .map((example: Example) => (
                    <ListItem key={example.id} sx={{ pl: 4 }}>
                      <ListItemText
                        primary={`Question: ${example.question}`}
                        secondary={example.answers.map((answer, index) => (
                          <Chip
                            key={index}
                            label={answer.text}
                            sx={{
                              mr: 1,
                              mt: 1,
                              bgcolor: answer.isCorrect
                                ? green[400]
                                : undefined,
                            }}
                          />
                        ))}
                      />
                      <IconButton
                        onClick={() => handleUpdateExample(example, exercise)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRemoveExample(example.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </Paper>
      {isAddExampleOpen && selectedExerciseGroup && (
        <AddExampleForm
          isOpen={isAddExampleOpen}
          onClose={() => setIsAddExampleOpen(false)}
          exerciseGroupId={selectedExerciseGroup.id}
          exerciseGroupType={
            selectedExerciseGroup?.exerciseType?.name || "ABCD"
          }
        />
      )}
      {isEditExampleOpen && currentExample && (
        <EditExampleForm
          isOpen={isEditExampleOpen}
          onClose={handleCloseEditForm}
          example={currentExample}
          exerciseGroupType={selectedExerciseGroup?.exerciseType.name || "ABCD"}
        />
      )}
      {isEditGroupOpen && selectedGroupForEdit && (
        <EditExerciseGroupForm
          isOpen={isEditGroupOpen}
          onClose={() => setIsEditGroupOpen(false)}
          group={selectedGroupForEdit}
          onSave={handleSaveEditedGroup}
        />
      )}
      <AddExerciseGroupForm />
    </div>
  );
};

export default Exercises;
