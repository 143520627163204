import React, { useState, useEffect } from "react";
import {
  useGetRequirementGroupsQuery,
  useEditRequirementGroupMutation,
  useDeleteRequirementGroupMutation,
} from "../../../services/requirementGroups"; // Adjust import path as needed
import {
  RequirementGroup,
  Requirement,
  EditRequirementGroupPayload,
} from "../../../interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useGetRequirementsQuery } from "../../../services/requirements";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddRequirementGroupForm from "./AddRequirementGroupForm";

const Requirements = () => {
  const {
    data: requirementGroups,
    error: requirementGroupsError,
    isLoading: requirementGroupsLoading,
  } = useGetRequirementGroupsQuery(); // Changed to useGetRequirementGroupsQuery
  const {
    data: allRequirements,
    error: requirementsError,
    isLoading: requirementsLoading,
  } = useGetRequirementsQuery();
  const [editRequirementGroup, { isLoading: isEditing }] =
    useEditRequirementGroupMutation(); // Changed to useEditRequirementGroupMutation

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentRequirementGroup, setCurrentRequirementGroup] =
    useState<RequirementGroup | null>(null);
  const [requirementGroupName, setRequirementGroupName] = useState("");
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [originalTopicIds, setOriginalTopicIds] = useState<string[]>([]);

  useEffect(() => {
    if (currentRequirementGroup) {
      const requirementIds = currentRequirementGroup.requirements?.map(
        (requirement: Requirement) => requirement.id
      );
      setSelectedTopicIds(requirementIds);
      setOriginalTopicIds(requirementIds); // Capture the original topic IDs when the dialog opens
      setRequirementGroupName(currentRequirementGroup.name);
    }
  }, [currentRequirementGroup]);

  const handleOpenEditDialog = (requirementGroup: RequirementGroup) => {
    setCurrentRequirementGroup(requirementGroup);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentRequirementGroup(null);
    setSelectedTopicIds([]);
  };

  const handleToggleTopic = (topicId: string) => {
    setSelectedTopicIds((prevSelected) => {
      // Ensure prevSelected is always an array
      const currentSelected = prevSelected || [];
      return currentSelected.includes(topicId)
        ? currentSelected?.filter((id) => id !== topicId)
        : [...currentSelected, topicId];
    });
  };

  const handleEditRequirementGroup = async () => {
    if (currentRequirementGroup) {
      // Calculate requirements to add and to remove based on the difference
      const requirementsToAdd = selectedTopicIds?.filter(
        (id) => !originalTopicIds?.includes(id)
      );
      const requirementsToRemove = originalTopicIds?.filter(
        (id) => !selectedTopicIds?.includes(id)
      );

      const payload: EditRequirementGroupPayload = {
        id: currentRequirementGroup.id,
        data: {
          name: requirementGroupName,
          requirementIdsToAdd: requirementsToAdd,
          requirementIdsToRemove: requirementsToRemove,
        },
      };

      await editRequirementGroup(payload);
      handleCloseDialog();
    }
  };

  const [removeRequirementGroup] = useDeleteRequirementGroupMutation(); // Changed to useRemoveRequirementGroupMutation

  const handleRemoveRequirementGroup = async (requirementGroupId: string) => {
    // Confirm with the user before deleting
    if (
      window.confirm("Are you sure you want to delete this requirement group?")
    ) {
      await removeRequirementGroup(requirementGroupId).unwrap();
      // Additional logic after deletion if needed
    }
  };

  if (requirementGroupsLoading || requirementsLoading)
    return <div>Loading...</div>;
  if (requirementGroupsError)
    return <div>Error occurred: {requirementGroupsError.toString()}</div>;
  if (requirementsError)
    return <div>Error occurred: {requirementsError.toString()}</div>;

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ my: 4 }}>
          Requirement Groups
        </Typography>
        {requirementGroups?.map((requirementGroup) => (
          <Paper key={requirementGroup.id} elevation={2} sx={{ p: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{requirementGroup.name}</Typography>
              <Box>
                <IconButton
                  onClick={() => handleOpenEditDialog(requirementGroup)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    handleRemoveRequirementGroup(requirementGroup.id)
                  }
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Stack direction="row" spacing={1} mt={2}>
              {requirementGroup.requirements?.map(
                (requirement: Requirement) => (
                  <Chip key={requirement.id} label={requirement.name} />
                )
              )}
            </Stack>
          </Paper>
        ))}
        <AddRequirementGroupForm />

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <Box p={2} component="form" onSubmit={(e) => e.preventDefault()}>
            <TextField
              fullWidth
              label="Requirement Group Name"
              value={requirementGroupName}
              onChange={(e) => setRequirementGroupName(e.target.value)}
              margin="normal"
            />
            <Box sx={{ my: 2 }}>
              {allRequirements?.map((requirement) => (
                <Chip
                  key={requirement.id}
                  label={requirement.name}
                  onClick={() => handleToggleTopic(requirement.id)}
                  color={
                    selectedTopicIds?.includes(requirement.id)
                      ? "primary"
                      : "default"
                  }
                  clickable
                  variant="outlined"
                  sx={{ margin: 0.5 }}
                />
              ))}
            </Box>
            <Button
              type="button"
              onClick={handleEditRequirementGroup}
              disabled={isEditing}
              variant="contained"
            >
              Save Changes
            </Button>
          </Box>
        </Dialog>
      </Container>
    </div>
  );
};

export default Requirements;
