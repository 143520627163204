import React from "react";
import { Drawer, Tabs, Tab, Box, Button } from "@mui/material";
import Departments from "../Departments/Departments";
import Topics from "../Topics/Topics";
import Exercises from "../Exercises/Exercises";
import Requirements from "../Requirements/Requirements";
import ExerciseTypes from "../ExerciseTypes/ExerciseTypes";
import { logout } from "../../../services/auth";
import { useNavigate } from "react-router-dom";

function DrawerWithTabs() {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const tabContent = () => {
    switch (selectedTab) {
      case 0:
        return <Departments />;
      case 1:
        return <Topics />;
      case 2:
        return <Exercises />;
      case 3:
        return <ExerciseTypes />;
      case 4:
        return <Requirements />;
      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="Działy" />
            <Tab label="Tematy" />
            <Tab label="Zadania" />
            <Tab label="Typy zadań" />
            <Tab label="Wymogi" />
          </Tabs>
          <Button
            onClick={handleLogout}
            variant="contained"
            color="primary"
            sx={{ mt: 15 }}
          >
            Logout
          </Button>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        {tabContent()}
      </Box>
    </Box>
  );
}

export default DrawerWithTabs;
