import { createTheme } from "@mui/material/styles";
import typography from "./typography"; // adjust the path as needed

// Step 1: Create the base theme
let theme = createTheme({
  typography: {
    fontSize: typography.typography.fontSize,
    fontFamily: typography.typography.fontFamily,
    h1: typography["typography/h1"],
    h2: typography["typography/h2"],
    h3: typography["typography/h3"],
    h4: typography["typography/h4"],
    h5: typography["typography/h5"],
    h6: typography["typography/h6"],
    body1: typography["typography/body1"],
    body2: typography["typography/body2"],
    subtitle1: typography["typography/subtitle1"],
    subtitle2: typography["typography/subtitle2"],
    overline: typography["menu/itemdense"],
    caption: typography["typography/caption"],
    button: typography["button/large"],
    // Add other custom typography styles here if needed
  },
});

// Step 2: Add responsive typography settings
theme = createTheme(theme, {
  typography: {
    h1: {
      ...theme.typography.h1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 28,
      },
    },

    h4: {
      ...theme.typography.h4,
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
  },
});

export default theme;
