import React, { useState, useEffect } from "react";
import {
  useEditDepartmentMutation,
  useRemoveDepartmentMutation,
  useGetAllDepartmentsQuery,
} from "../../../services/departments";
import { useGetTopicsQuery } from "../../../services/topics";
import { Department, EditDepartmentPayload, Topic } from "../../../interfaces";
import {
  Button,
  Chip,
  Dialog,
  TextField,
  Box,
  Stack,
  IconButton,
  Paper,
  Typography,
  Container,
} from "@mui/material";
import AddDepartmentForm from "./AddDepartmentForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Departments = () => {
  const {
    data: departments,
    error: departmentsError,
    isLoading: departmentsLoading,
  } = useGetAllDepartmentsQuery({});
  const {
    data: allTopics,
    error: topicsError,
    isLoading: topicsLoading,
  } = useGetTopicsQuery({});
  const [editDepartment, { isLoading: isEditing }] =
    useEditDepartmentMutation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState<Department | null>(
    null
  );
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState<string | undefined>("");
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [originalTopicIds, setOriginalTopicIds] = useState<string[]>([]);

  useEffect(() => {
    if (currentDepartment) {
      const topicIds = currentDepartment.topics?.map((topic) => topic.id);
      setSelectedTopicIds(topicIds);
      setOriginalTopicIds(topicIds); // Capture the original topic IDs when the dialog opens
      setDepartmentName(currentDepartment.name);
      setDescription(currentDepartment.description);
    }
  }, [currentDepartment]);

  const handleOpenEditDialog = (department: Department) => {
    setCurrentDepartment(department);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentDepartment(null);
    setSelectedTopicIds([]);
  };

  const handleToggleTopic = (topicId: string) => {
    setSelectedTopicIds((prevSelected) => {
      // Ensure prevSelected is always an array
      const currentSelected = prevSelected || [];
      return currentSelected.includes(topicId)
        ? currentSelected?.filter((id) => id !== topicId)
        : [...currentSelected, topicId];
    });
  };

  const handleEditDepartment = async () => {
    if (currentDepartment) {
      // Calculate topics to add and to remove based on the difference
      const topicsToAdd = selectedTopicIds?.filter(
        (id) => !originalTopicIds?.includes(id)
      );
      const topicsToRemove = originalTopicIds?.filter(
        (id) => !selectedTopicIds?.includes(id)
      );

      const payload: EditDepartmentPayload = {
        id: currentDepartment.id,
        data: {
          name: departmentName,
          description,
          topicIdsToAdd: topicsToAdd,
          topicIdsToRemove: topicsToRemove,
        },
      };

      await editDepartment(payload);
      handleCloseDialog();
    }
  };

  const [removeDepartment] = useRemoveDepartmentMutation();

  const handleRemoveDepartment = async (departmentId: string) => {
    // Confirm with the user before deleting
    if (window.confirm("Are you sure you want to delete this department?")) {
      await removeDepartment(departmentId).unwrap();
      // Additional logic after deletion if needed
    }
  };

  if (departmentsLoading || topicsLoading) return <div>Loading...</div>;
  if (departmentsError)
    return <div>Error occurred: {departmentsError.toString()}</div>;
  if (topicsError) return <div>Error occurred: {topicsError.toString()}</div>;

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ my: 4 }}>
          Departments
        </Typography>
        {departments?.map((department) => (
          <Paper key={department.id} elevation={2} sx={{ p: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{department.name}</Typography>
              <Box>
                <IconButton
                  onClick={() => handleOpenEditDialog(department)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDepartment(department.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Typography>{department.description}</Typography>
            <Stack direction="row" spacing={1} mt={2}>
              {department.topics?.map((topic) => (
                <Chip key={topic.id} label={topic.name} />
              ))}
            </Stack>
          </Paper>
        ))}
        <AddDepartmentForm />

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <Box p={2} component="form" onSubmit={(e) => e.preventDefault()}>
            <TextField
              fullWidth
              label="Nazwa działu"
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Opis Działu"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
            />
            <Box sx={{ my: 2 }}>
              {allTopics?.map((topic) => (
                <Chip
                  key={topic.id}
                  label={topic.name}
                  onClick={() => handleToggleTopic(topic.id)}
                  color={
                    selectedTopicIds?.includes(topic.id) ? "primary" : "default"
                  }
                  clickable
                  variant="outlined"
                  sx={{ margin: 0.5 }}
                />
              ))}
            </Box>
            <Button
              type="button"
              onClick={handleEditDepartment}
              disabled={isEditing}
              variant="contained"
            >
              Save Changes
            </Button>
          </Box>
        </Dialog>
      </Container>
    </div>
  );
};

export default Departments;
