import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AdminPanel from "./pages/AdminPanel";
import DepartmentsPage from "./pages/DepartmentsPage/DepartmentsPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import "./fonts.scss";
import TopicsPage from "./pages/TopicsPage/TopicsPage";
import ExercisesPage from "./pages/ExercisesPage/ExercisesPage";
import ExamplePage from "./pages/ExamplePage/ExamplePage";
import { getCurrentUserRole, isLoggedIn } from "./services/auth";
import { Helmet } from "react-helmet";
import CookieConsentComponent from "./components/common/CookieConsent/CookieConsent";
import { useEffect, useState } from "react";
import { getCookieConsent } from "./utils/indexedDB";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";

const PrivateRoute = ({ children, role }: { children: any; role: string }) => {
  const location = useLocation();

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const userRole = getCurrentUserRole();
  if (role && userRole !== role) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  const [isConsentSigned, setIsConsentSigned] = useState<boolean>(false);

  useEffect(() => {
    const checkConsent = async () => {
      const status = await getCookieConsent();
      if (status) {
        setIsConsentSigned(true);
      }
    };
    checkConsent();
  }, []);

  return (
    <Router>
      <Helmet>
        <title>{"Examly"}</title>
      </Helmet>
      <Routes>
        <Route
          path="/admin"
          element={
            <PrivateRoute role="admin">
              <AdminPanel />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route path="/dzialy" element={<DepartmentsPage />} />
        <Route path="/tematy/:name" element={<TopicsPage />} />
        <Route path="/zadania/:name" element={<ExercisesPage />} />
        <Route path="/rozwiaz/:name" element={<ExamplePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/rejestracja" element={<RegisterPage />} />
        <Route path="/polityka-prywatnosci" element={<PrivacyPolicyPage />} />
      </Routes>
      {!isConsentSigned && <CookieConsentComponent />}
    </Router>
  );
}

export default App;
