import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ExerciseType, IAnswer } from "../../interfaces";
import { EexerciseType } from "../../enums";
import { BlockMath } from "react-katex"; // Import BlockMath for LaTeX rendering
import "katex/dist/katex.min.css"; // Import KaTeX CSS

interface AnswerChoiceProps {
  answers: IAnswer[] | undefined;
  exerciseType: ExerciseType;
  setUserAnswers: (userAnswers: string[]) => void;
  userAnswers: string[];
}

function shuffleArray(array: IAnswer[] | undefined) {
  if (!array) {
    return null;
  }
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

export default function AnswerChoice({
  answers,
  exerciseType,
  setUserAnswers,
  userAnswers,
}: AnswerChoiceProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [shuffledAnswers, setShuffledAnswers] = useState<IAnswer[] | null>(
    null
  );

  useEffect(() => {
    setShuffledAnswers(shuffleArray(answers));
  }, [answers]);

  useEffect(() => {
    if (exerciseType.id === EexerciseType.short) {
      setUserAnswers(userAnswers);
    } else if (selectedAnswer !== null) {
      setUserAnswers([selectedAnswer]);
    }
  }, [userAnswers, selectedAnswer, exerciseType.id, setUserAnswers]);

  const handleShortAnswerChange = (index: number, value: string) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
  };

  if (exerciseType.id === EexerciseType.short && answers) {
    return (
      <Stack direction="row" spacing={2} mt={4}>
        {answers.map((answer, index) => (
          <React.Fragment key={index}>
            <Typography>{`${String.fromCharCode(index + 65)}:`}</Typography>
            <TextField
              label={`Odpowiedź ${index + 1}`}
              value={userAnswers[index] || ""}
              onChange={(e) => handleShortAnswerChange(index, e.target.value)}
            />
          </React.Fragment>
        ))}
      </Stack>
    );
  }

  if (exerciseType.id !== EexerciseType.short && answers) {
    return (
      <Stack mt={4}>
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          name="answers"
          value={selectedAnswer}
          onChange={(e) => setSelectedAnswer(e.target.value)}
        >
          {shuffledAnswers?.map((answer, index) => (
            <FormControlLabel
              key={index}
              value={answer.text}
              control={<Radio />}
              label={
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body1" component="span" mr={1}>
                    {String.fromCharCode(65 + index)}.
                  </Typography>
                  {/* Render LaTeX if present, otherwise render as plain text */}
                  {answer.text.includes("\\") ? (
                    <Typography variant="body1">
                      <BlockMath>{answer.text}</BlockMath>
                    </Typography>
                  ) : (
                    answer.text
                  )}
                </Typography>
              }
              sx={{
                marginRight: "30px",
              }} // Add spacing between buttons if needed
            />
          ))}
        </RadioGroup>
      </Stack>
    );
  }

  return null;
}
