import React from "react";
import { useAddExerciseMutation } from "../../../services/exercises"; // Adjust import path as needed
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useGetExerciseTypesQuery } from "../../../services/exerciseTypes";

interface IFormInput {
  name: string; // The name of the exercise group
  exerciseTypeId: string; // The selected exercise type ID, initially empty or null
  isLeavingExamGroup: boolean;
  description: string;
}

const AddExerciseGroupForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IFormInput>();
  const [addExerciseGroup, { isLoading, isSuccess, isError, error }] =
    useAddExerciseMutation();
  const { data: exerciseTypes, isLoading: isExerciseTypesLoading } =
    useGetExerciseTypesQuery();

  const onSubmit = async (data: IFormInput) => {
    await addExerciseGroup({
      name: data.name,
      exerciseTypeId: data.exerciseTypeId,
      isLeavingExamGroup: data.isLeavingExamGroup,
      description: data.description,
    });
    reset(); // Reset the form fields after submission
  };

  if (isExerciseTypesLoading) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" flexDirection="column" gap={2} maxWidth={400}>
        <TextField
          {...register("name", { required: "Nazwa grupy zadań jest wymagana" })}
          label="Nazwa grupy zadań"
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name?.message}
          disabled={isLoading}
        />
        <TextField
          {...register("description")}
          label="Opis grupy zadań"
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name?.message}
          disabled={isLoading}
        />
        <FormControl fullWidth>
          <InputLabel id="exercise-type-label">Exercise Type</InputLabel>
          <Select
            labelId="exercise-type-label"
            {...register("exerciseTypeId", {
              required: "Typ zadania jest wymagany",
            })}
            label="Typ zadania"
            defaultValue=""
            error={!!errors.exerciseTypeId}
          >
            {exerciseTypes?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Controller
              name="isLeavingExamGroup"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={isLoading}
                />
              )}
            />
          }
          label="Grupa zadań maturalnych"
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Dodaj grupę zadań"}
        </Button>
        {isSuccess && (
          <Alert severity="success">Grupa zadań dodana pomyślnie!</Alert>
        )}
        {isError && (
          <Alert severity="error">
            Błąd przy tworzeniu grupy zadań: {error?.toString()}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default AddExerciseGroupForm;
