import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useLoginUserMutation } from "../../services/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import Navbar from "../../components/common/Navbar/Navbar";
import { Helmet } from "react-helmet";

export default function LoginPage() {
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const { accessToken, role } = await loginUser({
        email,
        password,
      }).unwrap();
      alert("Login successful");
      localStorage.setItem("token", accessToken);
      localStorage.setItem("role", role);

      const from = location.state?.from?.pathname || "/admin";
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error, "err");
      alert("Login failed");
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Examly"}</title>
      </Helmet>
      <Navbar />
      <Container maxWidth="sm" className="login-page">
        <Box className="login-form" boxShadow={3} padding={3}>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="submit-button"
              type="submit"
              disabled={isLoading}
            >
              Login
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
}
