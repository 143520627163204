import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import colors from "../../config/colors";
import { Button, Grid } from "@mui/material";
import { ExerciseGroup, ExerciseType } from "../../interfaces";
import { Link } from "react-router-dom";
import normalizeUrlName from "../../utils/normalizeName";

interface ExerciseCardProps {
  exercise: ExerciseGroup;
  key: string;
}

export default function ExerciseCard({ exercise, key }: ExerciseCardProps) {
  return (
    <Grid item key={key} xs={11} sm={5} md={3}>
      <Card
        sx={{
          padding: "24px 16px",
          boxShadow: "0px 2px 10px 0px rgba(66, 68, 90, 0.25)",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h5" component="h3">
              {exercise.name}
            </Typography>
          }
          sx={{
            textAlign: "left",
            padding: 0,
            height: "64px", // Set the height to ensure it fits two lines of text
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis", // Add ellipsis if text overflows
            display: "-webkit-box",
            WebkitLineClamp: 2, // Limit to 2 lines
            WebkitBoxOrient: "vertical",
          }}
        />
        <CardContent sx={{ padding: 0, mt: 1 }}>
          <Typography
            variant="body2"
            color={colors.text.primary}
            sx={{
              textAlign: "left",
              height: "64px", // Set the height to fit two lines of text
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Add ellipsis if text overflows
              display: "-webkit-box",
              WebkitLineClamp: 3, // Limit to 2 lines
              WebkitBoxOrient: "vertical",
              color: "gray",
            }}
          >
            {exercise.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ padding: 0, mt: 4 }}>
          <Link
            to={`/rozwiaz/${normalizeUrlName(exercise.name)}`}
            state={{
              exerciseId: exercise.id,
              exerciseType: exercise.exerciseType,
              exerciseName: exercise.name,
            }}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Button
              variant="contained"
              sx={{
                background: colors.primary.dark,
                padding: "18px 24px",
                width: "100%",
                borderRadius: "4px",
                "&:hover": {
                  background: colors.primary.dark,
                  filter: "brightness(90%)",
                },
              }}
            >
              <Typography color={colors.contrast} variant="button">
                Wybierz
              </Typography>
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
}
