import React from "react";
import DrawerWithTabs from "../components/AdminPanel/DrawerWithTabs/DrawerWithTabs";
import { Helmet } from "react-helmet";

const AdminPanel = () => {
  return (
    <div>
      <Helmet>
        <title>{"Examly-Admin"}</title>
      </Helmet>
      <DrawerWithTabs />
    </div>
  );
};

export default AdminPanel;
