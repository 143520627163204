import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EditTopicDto, Topic } from "../interfaces";

export const topicsApi = createApi({
  reducerPath: "topicsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Topic"],
  endpoints: (builder) => ({
    getTopics: builder.query<
      Topic[],
      { departmentId?: number; topicName?: string | null }
    >({
      query: ({ departmentId, topicName }) => {
        let queryString = `/topics?`;
        if (departmentId) {
          queryString += `departmentId=${departmentId}`;
        }
        if (topicName) {
          queryString += `&topicName=${encodeURIComponent(topicName)}`;
        }
        return queryString;
      },
      providesTags: ["Topic"],
    }),
    addTopic: builder.mutation<Topic, Partial<Topic>>({
      query: (newTopic) => ({
        url: "/topics",
        method: "POST",
        body: newTopic,
      }),
      invalidatesTags: ["Topic"],
    }),
    editTopic: builder.mutation<
      Topic,
      { id: string; data: Partial<EditTopicDto> }
    >({
      query: ({ id, data }) => ({
        url: `/topics/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Topic"],
    }),
    removeTopic: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/topics/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Topic"],
    }),
  }),
});

export const {
  useGetTopicsQuery,
  useLazyGetTopicsQuery,
  useAddTopicMutation,
  useEditTopicMutation,
  useRemoveTopicMutation,
} = topicsApi;
