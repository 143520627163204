import React from "react";
import Navbar from "../../components/common/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Footer from "../../components/common/Footer/Footer";
import { Card } from "@mui/material";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Helmet defer={false}>
        <title>{"Examly | Polityka prywatności"}</title>
      </Helmet>
      <Navbar />
      <Card
        sx={{
          height: "calc(100vh - 261px)",
          padding: "24px 16px",
          mt: 4,
          mb: 4,
          mx: 4,
          boxShadow: "0px 2px 10px 0px rgba(66, 68, 90, 0.25)",
          minHeight: "200px",
          display: "flex",
          maxHeight: "calc(100vh - 149px)",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        1. Wstęp <br /> <br />
        Niniejsza Polityka Prywatności określa zasady przetwarzania danych
        osobowych oraz używania plików cookies na stronie internetowej
        Examly.eu, zajmującej się udostępnianiem zadań z matematyki.
        <br /> <br />
        2. Administrator Danych
        <br />
        <br /> Administratorem danych osobowych jest Lumi IT. Możesz się z nami
        skontaktować za pośrednictwem emaila examlymatematyka@gmail.com.
        <br /> <br />
        3. Pliki Cookies
        <br />
        <br /> Pliki cookies są małymi plikami tekstowymi przechowywanymi na
        Twoim urządzeniu końcowym (np. komputerze, smartfonie) podczas
        korzystania z naszej strony internetowej.
        <br /> <br />
        3.1. Cel wykorzystania cookies
        <br />
        <br /> Na naszej stronie pliki cookies są wykorzystywane wyłącznie w
        celu personalizacji reklam. Dzięki nim możemy dostarczać treści
        reklamowe, które są bardziej dostosowane do Twoich zainteresowań.
        <br /> <br />
        3.2. Zarządzanie plikami cookies
        <br />
        <br /> Możesz zarządzać ustawieniami cookies, w tym blokować lub usuwać
        pliki cookies, korzystając z ustawień swojej przeglądarki internetowej.
        Ograniczenie stosowania plików cookies może wpłynąć na funkcjonalność
        strony.
        <br />
        <br /> 4. IndexedDB
        <br />
        <br /> Na stronie Examly.eu używamy również narzędzia IndexedDB, które
        jest lokalną bazą danych przechowywaną na Twoim urządzeniu. Służy ono
        wyłącznie do poprawy Twojego doświadczenia związanego z korzystaniem z
        naszej strony, w tym do zarządzania powtarzalnością przykładów
        matematycznych. IndexedDB nie jest wykorzystywane do gromadzenia ani
        przetwarzania Twoich danych osobowych.
        <br />
        <br /> 5. Zmiany w Polityce Prywatności
        <br /> <br />
        Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce
        Prywatności. Wszelkie zmiany będą publikowane na tej stronie, a o
        istotnych zmianach będziemy informować użytkowników za pośrednictwem
        dostępnych kanałów komunikacji.
        <br />
        <br /> 6. Kontakt
        <br />
        <br /> Jeśli masz pytania dotyczące naszej Polityki Prywatności,
        skontaktuj się z nami za pośrednictwem maila examlymatematyka@gmail.com.
      </Card>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
