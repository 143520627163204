import { openDB } from "idb";

const DB_NAME = "examlyDB";
const STORE_NAME = "exerciseGroups";
const CONSENT_STORE_NAME = "cookieConsent";

// Open the database and create the object store if it doesn't exist
const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    } else {
      console.log(`Object store already exists: ${STORE_NAME}`);
    }

    if (!db.objectStoreNames.contains(CONSENT_STORE_NAME)) {
      console.log(`Creating object store: ${CONSENT_STORE_NAME}`);
      db.createObjectStore(CONSENT_STORE_NAME);
    } else {
      console.log(`Object store already exists: ${CONSENT_STORE_NAME}`);
    }
  },
});

interface ExerciseGroup {
  completed: string[];
  skipped: string[];
}

export async function addCompletedExercise(
  groupId: string,
  exerciseId: string
): Promise<void> {
  try {
    const db = await dbPromise;

    const tx = db.transaction(STORE_NAME, "readwrite");

    const store = tx.objectStore(STORE_NAME);

    let groupData: ExerciseGroup | undefined = await store.get(groupId);

    if (!groupData) {
      groupData = { completed: [], skipped: [] }; // Initialize if no record exists
    }

    if (!groupData.completed.includes(exerciseId)) {
      groupData.completed.push(exerciseId);
    }

    // Save the updated group data back to IndexedDB
    await store.put(groupData, groupId);
    await tx.done;
  } catch (error) {
    console.error("Error adding completed exercise:", error);
  }
}

export async function addSkippedExercise(
  groupId: string,
  exerciseId: string
): Promise<void> {
  try {
    const db = await dbPromise;

    const tx = db.transaction(STORE_NAME, "readwrite");

    const store = tx.objectStore(STORE_NAME);

    // Get the existing record for this group ID
    let groupData: ExerciseGroup | undefined = await store.get(groupId);

    if (!groupData) {
      groupData = { completed: [], skipped: [] }; // Initialize if no record exists
    }

    if (!groupData.skipped.includes(exerciseId)) {
      groupData.skipped.push(exerciseId);
    }

    // Save the updated group data back to IndexedDB
    await store.put(groupData, groupId);
    await tx.done;
  } catch (error) {
    console.error("Error adding skipped exercise:", error);
  }
}

export async function isExerciseCompleted(
  groupId: string,
  exerciseId: string
): Promise<boolean> {
  try {
    const db = await dbPromise;

    const groupData: ExerciseGroup | undefined = await db.get(
      STORE_NAME,
      groupId
    );

    return !!groupData?.completed.includes(exerciseId);
  } catch (error) {
    console.error("Error checking if exercise is completed:", error);
    return false;
  }
}

export async function isExerciseSkipped(
  groupId: string,
  exerciseId: string
): Promise<boolean> {
  try {
    const db = await dbPromise;

    const groupData: ExerciseGroup | undefined = await db.get(
      STORE_NAME,
      groupId
    );

    return !!groupData?.skipped.includes(exerciseId);
  } catch (error) {
    console.error("Error checking if exercise is skipped:", error);
    return false;
  }
}

export async function clearCompletedExercises(groupId: string): Promise<void> {
  try {
    const db = await dbPromise;

    const tx = db.transaction(STORE_NAME, "readwrite");

    const store = tx.objectStore(STORE_NAME);

    // Clear the completed exercises array for the specified group ID
    let groupData: ExerciseGroup | undefined = await store.get(groupId);

    if (groupData) {
      groupData.completed = [];
      await store.put(groupData, groupId);
    }

    await tx.done;
  } catch (error) {
    console.error("Error clearing completed exercises:", error);
  }
}

export async function getExerciseGroupData(
  groupId: string
): Promise<ExerciseGroup | undefined> {
  try {
    const db = await dbPromise;
    const groupData: ExerciseGroup | undefined = await db.get(
      STORE_NAME,
      groupId
    );
    return groupData;
  } catch (error) {
    console.error("Error retrieving exercise group data:", error);
    return undefined;
  }
}

export async function clearExerciseGroupData(groupId: string): Promise<void> {
  try {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);

    await store.delete(groupId);
    await tx.done;
  } catch (error) {
    console.error("Error clearing exercise group data:", error);
  }
}

export async function setCookieConsent(status: string): Promise<void> {
  try {
    const db = await dbPromise;
    const tx = db.transaction(CONSENT_STORE_NAME, "readwrite");
    const store = tx.objectStore(CONSENT_STORE_NAME);
    await store.put(status, "consentStatus");
    await tx.done;
  } catch (error) {
    console.error("Error saving cookie consent status:", error);
  }
}

export async function getCookieConsent(): Promise<string | undefined> {
  try {
    const db = await dbPromise;
    const status = await db.get(CONSENT_STORE_NAME, "consentStatus");
    return status;
  } catch (error) {
    console.error("Error retrieving cookie consent status:", error);
    return undefined;
  }
}
