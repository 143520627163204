import React, { useState } from 'react';
import {
  useGetExerciseTypesQuery,
  useEditExerciseTypeMutation,
  useRemoveExerciseTypeMutation,
} from '../../../services/exerciseTypes';
import { ExerciseType } from '../../../interfaces';
import {
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  IconButton,
  Dialog,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import AddExerciseTypeForm from './AddExerciseTypeForm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { EexerciseType } from '../../../enums';

const ExerciseTypes = () => {
  const {
    data: exerciseTypes,
    error,
    isLoading,
    refetch,
  } = useGetExerciseTypesQuery();
  const [editExerciseType] = useEditExerciseTypeMutation();
  const [removeExerciseType] = useRemoveExerciseTypeMutation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentExerciseType, setCurrentExerciseType] =
    useState<ExerciseType | null>(null);

  const openEditDialog = (exerciseType: ExerciseType) => {
    setCurrentExerciseType(exerciseType);
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentExerciseType(null);
  };

  const handleClose = () => setOpen(false);

  const handleEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get('name') as string;
    if (currentExerciseType && name) {
      await editExerciseType({ id: currentExerciseType.id, data: { name } });
      closeEditDialog();
      refetch(); // Refetch the exercise types list to reflect the update
    }
  };

  const handleRemove = async (exerciseTypeId: EexerciseType) => {
    if (window.confirm('Are you sure you want to delete this exercise type?')) {
      await removeExerciseType(exerciseTypeId);
      refetch(); // Refetch to update the list after removal
    }
  };

  if (isLoading)
    return (
      <Box display='flex' justifyContent='center'>
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Typography variant='body1' color='error'>
        Wystąpił błąd: {error.toString()}
      </Typography>
    );

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Typy zadań
      </Typography>
      <List component={Paper} sx={{ padding: 0 }}>
        {exerciseTypes?.map((exerciseType: ExerciseType) => (
          <ListItem
            key={exerciseType.id}
            divider
            secondaryAction={
              <>
                <IconButton
                  edge='end'
                  aria-label='edit'
                  onClick={() => openEditDialog(exerciseType)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleRemove(exerciseType.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={exerciseType.name} />
          </ListItem>
        ))}
      </List>
      <Box mt={2}>
        <AddExerciseTypeForm
          onSuccess={() => {
            handleClose(); // Close the form upon successful submission
            // You might also want to refresh a list or state here if needed
          }}
        />
      </Box>

      <Dialog
        open={editDialogOpen}
        onClose={closeEditDialog}
        aria-labelledby='edit-dialog-title'
      >
        <form onSubmit={handleEditSubmit}>
          <DialogTitle id='edit-dialog-title'>Edytuj typ zadania</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              name='name'
              label='Nazwa typu zadania'
              type='text'
              fullWidth
              variant='outlined'
              defaultValue={currentExerciseType?.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditDialog}>Anuluj</Button>
            <Button type='submit'>Zapisz</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ExerciseTypes;
