import { Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import colors from "../../../config/colors";
import { Link } from "react-router-dom";

const Footer = () => {
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  return (
    <Stack
      flexDirection={isMediumScreen ? "row" : "column"}
      justifyContent={isMediumScreen ? "space-between" : "flex-end"}
      alignItems={isMediumScreen ? "center" : "center"}
      sx={{ background: colors.background.secondary, paddingX: 3, paddingY: 2 }}
    >
      <Typography color={colors.contrast}>
        Materiały maturalne są własnością CKE
      </Typography>
      <Stack>
        <Typography color={colors.contrast}>
          Examly jest tworzone przez Lumi IT
        </Typography>
        <Link to="polityka-prywatnosci">
          <Typography color={colors.contrast}>Polityka prywatności</Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
