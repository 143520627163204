import React, { useEffect, useState } from "react";
import { Grid, Typography, Container, Stack } from "@mui/material";
import Navbar from "../../components/common/Navbar/Navbar";
import "./styles.scss";
import { Box } from "@mui/material";
import colors from "../../config/colors";
import DepartmentCard from "../../components/DepartmentCard/DepartmentCard";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useLazyGetDepartmentsQuery } from "../../services/departments";
import { Helmet } from "react-helmet";

export default function DepartmentsPage() {
  const [searchedPhrase, setSearchedPhrase] = useState<string | null>(null);
  const [trigger, { data, isLoading, error }] = useLazyGetDepartmentsQuery();

  useEffect(() => {
    trigger({ departmentName: searchedPhrase });
  }, [searchedPhrase, trigger]);
  return (
    <Box>
      <Helmet>
        <title>{"Examly | Działy"}</title>
        <meta
          name="description"
          content="Odkryj zasady liczb rzeczywistych, zagłęb się w wyrażenia algebraiczne i zbiory liczbowe. Zdobądź pewność w dziedzinie matematyki dzięki naszym szczegółowym materiałom edukacyjnym."
        />
        <link rel="canonical" href={`https://examly.eu/dzialy`} />
      </Helmet>
      <Navbar />
      <Container sx={{ textAlign: "center", mt: 10, mb: 10 }}>
        <Typography variant="h1" color={colors.text.primary}>
          Wybierz interesujący cię dział
        </Typography>
        <Stack mt={4}>
          <SearchBar
            searchedPhrase={searchedPhrase}
            setSearchedPhrase={setSearchedPhrase}
          />
          <Grid
            container
            xs={12}
            mt={8}
            flexDirection="row"
            justifyContent="center"
            gap={8}
            flexWrap="wrap"
          >
            {data?.map((department) => {
              return (
                <DepartmentCard department={department} key={department.id} />
              );
            })}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
