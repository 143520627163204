import React from "react";
import { useAddDepartmentMutation } from "../../../services/departments";
import { useForm } from "react-hook-form";
import { TextField, Button, Box, CircularProgress, Alert } from "@mui/material";

interface IFormInput {
  departmentName: string;
  description?: string;
}

const AddDepartmentForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const [addDepartment, { isLoading, isSuccess, isError, error }] =
    useAddDepartmentMutation();

  const token = localStorage.getItem("token");

  const onSubmit = async (data: IFormInput) => {
    try {
      // Structure the data according to Partial<Department> type
      await addDepartment({
        department: {
          name: data.departmentName,
          description: data.description,
        },
      }).unwrap();

      reset(); // Reset the form fields
    } catch (error) {
      console.error("Failed to add department:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" flexDirection="column" gap={2} maxWidth={400}>
        <TextField
          {...register("departmentName", {
            required: "Department name is required",
          })}
          label="Nazwa działu"
          variant="outlined"
          error={!!errors.departmentName}
          helperText={errors.departmentName?.message}
          disabled={isLoading}
        />
        <TextField
          {...register("description")}
          label="Opis Działu"
          variant="outlined"
          disabled={isLoading}
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Add Department"}
        </Button>
        {isSuccess && (
          <Alert severity="success">Department added successfully!</Alert>
        )}
        {isError && (
          <Alert severity="error">
            Error adding department: {error?.toString()}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default AddDepartmentForm;
