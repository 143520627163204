const colors = {
  background: {
    primary: '#E0EEC6',
    secondary: '#7CA882',
    tetrary: '#F1F7ED',
  },
  primary: {
    main: '#E0EEC6',
    dark: '#243E36',
  },
  contrast: '#FFFFFF',
  text: {
    primary: '#18020C',
    secondary: '#18181A',
  },
  border: 'rgba(0, 0, 0, 0.08)',
};

export default colors;
