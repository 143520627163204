import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { setCookieConsent } from "../../../utils/indexedDB";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import colors from "../../../config/colors";

const CookieConsentComponent = () => {
  const handleAcceptAll = async () => {
    // Set all cookies
    Cookies.set("allowAll", true, { expires: 365 });
    Cookies.set("allowNecessary", true, { expires: 365 });
    await setCookieConsent("acceptedAll");
  };

  const handleAcceptNecessary = async () => {
    // Only set necessary cookies
    Cookies.set("allowNecessary", true, { expires: 365 });
    await setCookieConsent("acceptedNecessary");
  };

  const handleDeny = async () => {
    // Clear all cookies except necessary
    Cookies.set("allowAll", false);
    Cookies.set("allowNecessary", false);
    await setCookieConsent("denied");
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Potwierdź"
      declineButtonText="Odmów"
      style={{ background: "#2B373B", padding: "4px 6px" }}
      buttonStyle={{
        backgroundColor: "#4CAF50",
        color: "white",
        borderRadius: "6px",
        padding: "10px",
      }}
      declineButtonStyle={{
        backgroundColor: "#f44336",
        color: "white",
        padding: "10px",
        borderRadius: "6px",
      }}
      enableDeclineButton
      onAccept={handleAcceptAll}
      onDecline={handleDeny}
      cookieName="userConsent"
    >
      <Typography color={colors.contrast}>
        Na naszej stronie używamy ciasteczek wyłącznie w celu personalizowania
        ewentualnych reklam. Więcej informacji znajdziesz w naszej{" "}
        <Link to="/polityka-prywatnosci">
          <Typography
            color={colors.contrast}
            sx={{ background: "none", textDecoration: "underline" }}
            component="span"
          >
            Polityce prywatności.
          </Typography>
        </Link>
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
