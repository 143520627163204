import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface User {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  role: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    registerUser: builder.mutation<User, Partial<User>>({
      query: (newUser) => ({
        url: "/signup",
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ["User"],
    }),
    loginUser: builder.mutation<
      LoginResponse,
      { email: string; password: string }
    >({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response: LoginResponse) => {
        // Save the user data to local storage
        localStorage.setItem(
          "user",
          JSON.stringify({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            role: response.role,
          })
        );
        return response;
      },
    }),
  }),
});

export const { useRegisterUserMutation, useLoginUserMutation } = authApi;

export const logout = (): void => {
  localStorage.removeItem("user");
};

export const isLoggedIn = (): boolean => {
  const userJson = localStorage.getItem("user");
  if (userJson === null) {
    return false;
  }

  const user = JSON.parse(userJson);
  return user !== null;
};

export const getCurrentUserRole = (): string | null => {
  const userJson = localStorage.getItem("user");
  if (userJson === null) {
    return null;
  }

  const user = JSON.parse(userJson);
  return user ? user.role : null;
};
