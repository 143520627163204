import React, { useEffect, useState } from "react";
import {
  useGetTopicsQuery,
  useEditTopicMutation,
  useRemoveTopicMutation,
} from "../../../services/topics";
import { Topic, ExerciseGroup } from "../../../interfaces";
import AddTopicForm from "./AddTopicForm";
import {
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  IconButton,
  Dialog,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useGetExercisesQuery } from "../../../services/exercises";
import { useForm } from "react-hook-form";

interface IFormInput {
  topicName: string;
  description?: string;
}

const Topics = () => {
  const { data: topics, error, isLoading } = useGetTopicsQuery({});
  const { data: exerciseGroups } = useGetExercisesQuery({});
  const [editTopic] = useEditTopicMutation();

  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedExerciseGroupIds, setSelectedExerciseGroupIds] = useState<
    string[]
  >([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  const handleOpenAddExerciseGroup = (topic: Topic) => {
    setSelectedTopic(topic);
    // Populate with existing associations
    if (topic.exerciseGroups) {
      setSelectedExerciseGroupIds(
        topic.exerciseGroups?.map((eg: ExerciseGroup) => eg.id)
      );
    }
    setDialogOpen(true);
  };

  useEffect(() => {
    if (selectedTopic) {
      // Reset the form with the current topic's name and description
      reset({
        topicName: selectedTopic.name,
        description: selectedTopic.description || "",
      });
    }
  }, [selectedTopic, reset]);

  const [removeTopic] = useRemoveTopicMutation();

  const handleDeleteTopic = async (topic: Topic) => {
    if (topic) {
      await removeTopic(topic.id);
      setSelectedTopic(null);
    }
  };

  const handleToggleExerciseGroup = (exerciseGroupId: string) => {
    setSelectedExerciseGroupIds((prevSelected) => {
      return prevSelected.includes(exerciseGroupId)
        ? prevSelected.filter((id) => id !== exerciseGroupId)
        : [...prevSelected, exerciseGroupId];
    });
  };

  const handleSaveChanges = async (data: IFormInput) => {
    console.log("here i am");
    if (selectedTopic) {
      const currentExerciseGroupIds = selectedTopic.exerciseGroups?.map(
        (eg) => eg.id
      );
      const exerciseGroupIdsToAdd = selectedExerciseGroupIds.filter(
        (id) => !currentExerciseGroupIds?.includes(id)
      );
      const exerciseGroupIdsToRemove = currentExerciseGroupIds?.filter(
        (id) => !selectedExerciseGroupIds.includes(id)
      );

      await editTopic({
        id: selectedTopic.id,
        data: {
          // Include other fields as necessary, e.g., name or order if they are being updated
          exerciseGroupIdsToAdd,
          exerciseGroupIdsToRemove,
          name: data.topicName,
          description: data.description,
        },
      });

      console.log("here 2");

      reset();
      setDialogOpen(false);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error">Error occurred: {error.toString()}</Typography>
    );

  return (
    <div>
      <Typography variant="h4">Topics</Typography>
      <List component={Paper}>
        {topics?.map((topic) => (
          <>
            <ListItem
              key={topic.id}
              divider
              secondaryAction={
                <>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteTopic(topic)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleOpenAddExerciseGroup(topic)}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                primary={topic.name}
                secondary={
                  <>
                    {topic.department
                      ? `Dział: ${topic.department.name}`
                      : "Brak przypisanego działu"}
                    {topic.exerciseGroups &&
                      topic.exerciseGroups.length > 0 && (
                        <ul
                          style={{
                            margin: 0,
                            paddingLeft: "20px",
                            listStyleType: "circle",
                          }}
                        >
                          {topic.exerciseGroups.map((eg) => (
                            <li key={eg.id} style={{ marginTop: "4px" }}>
                              {eg.name}
                            </li>
                          ))}
                        </ul>
                      )}
                  </>
                }
              />
              <ListItemText primary={topic.description} />
            </ListItem>
          </>
        ))}
      </List>

      <AddTopicForm />
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <form onSubmit={handleSubmit(handleSaveChanges)} noValidate>
          <Box p={2}>
            <TextField
              {...register("topicName", {
                required: "Topic name is required",
              })}
              label="Nazwa tematu"
              variant="outlined"
              error={!!errors.topicName}
              helperText={errors.topicName?.message}
              disabled={isLoading}
            />
            <TextField
              {...register("description")}
              label="Opis tematu"
              variant="outlined"
              disabled={isLoading}
            />
            {exerciseGroups?.map((eg: ExerciseGroup) => (
              <Chip
                key={eg.id}
                label={eg.name}
                onClick={() => handleToggleExerciseGroup(eg.id)}
                color={
                  selectedExerciseGroupIds.includes(eg.id)
                    ? "primary"
                    : "default"
                }
                clickable
              />
            ))}
          </Box>
          <Button type="submit"> Save Changes</Button>
        </form>
      </Dialog>
    </div>
  );
};

export default Topics;
