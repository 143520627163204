import {
  Button,
  CardMedia,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import leavingExamExercisePhoto from "../../../assets/examples/leaving_exam_1.webp";
import leavingExamSectionPhoto from "../../../assets/examples/matura_exercises.webp";
import { Link } from "react-router-dom";
import colors from "../../../config/colors";

const LeavingExam = () => {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  return (
    <Grid
      container
      sx={{ paddingX: { xs: 2, lg: 6 }, mt: 12, mb: 6, display: "flex" }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" textAlign="center">
          Zadania maturalne przygotowane przez Centralną Komisję Egzaminacyjną
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        pr={{ xs: 0, lg: 4 }}
        pl={{ xs: 0, lg: 4 }}
        mt={isLargeScreen ? 6 : 4}
        order={isLargeScreen ? "initial" : 2}
      >
        <CardMedia component="img" image={leavingExamExercisePhoto} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        pl={{ xs: 1, lg: 6 }}
        pr={{ xs: 1, lg: 6 }}
        mt={6}
        order={isLargeScreen ? "initial" : 1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          variant="body2"
          component="p"
          textAlign="justify"
          letterSpacing="0.6px"
        >
          Na naszej stronie udostępniamy nieodpłatnie materiały edukacyjne
          przygotowane przez Centralną Komisję Egzaminacyjną i&nbsp;opracowane
          w&nbsp;formie zadań do&nbsp;rozwiązania. Pośród treści, podzielonych
          tematycznie, mogą pojawić się{" "}
          <strong>przykłady oznaczone jako Zadanie&nbsp;maturalne</strong>. To
          wybrane ćwiczenia, które nawiązują bezpośrednio do&nbsp;standardów
          obowiązujących na egzaminie maturalnym, a&nbsp;ich rozwiązanie pomoże
          w&nbsp;lepszym zrozumieniu wymagań egzaminacyjnych.
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent={isLargeScreen ? "flex-end" : "center"}
        >
          <Button
            component={Link}
            to="/dzialy"
            variant="contained"
            sx={{
              background: colors.primary.main,
              padding: "18px 24px",
              minWidth: "210px",
              borderRadius: "16px",
              "&:hover": {
                background: colors.primary.main,
                filter: "brightness(90%)",
              },
              mt: 4,
              width: "fit-content",
            }}
          >
            <Typography
              color={colors.text.primary}
              variant="button"
              textAlign="center"
            >
              Przejdź do zadań
            </Typography>
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        pl={{ xs: 1, lg: 6 }}
        pr={{ xs: 1, lg: 6 }}
        mt={6}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        order={isLargeScreen ? "initial" : 3}
      >
        <Typography
          variant="body2"
          component="p"
          textAlign="justify"
          letterSpacing="0.6px"
        >
          Dodatkowo, w specjalnej zakładce na&nbsp;stronie przygotowaliśmy{" "}
          <strong>zbiór egzaminów maturalnych z&nbsp;poprzednich lat</strong>,
          podzielony na roczniki. Dzięki temu uczniowie mają możliwość
          zapoznania się z&nbsp;regularnie uzupełnianymi arkuszami
          egzaminacyjnymi z lat ubiegłych, co&nbsp;pozwala na&nbsp;jeszcze
          lepsze przygotowanie się do nadchodzących wyzwań. Zachęcamy
          do&nbsp;korzystania z&nbsp;tych zasobów jako cennego wsparcia w nauce
          i&nbsp;samodzielnym przygotowaniu do&nbsp;egzaminów maturalnych.
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent={isLargeScreen ? "initial" : "center"}
        >
          <Button
            component={Link}
            to="#"
            variant="contained"
            sx={{
              background: colors.primary.dark,
              padding: "18px 24px",
              minWidth: "210px",
              borderRadius: "16px",
              "&:hover": {
                background: colors.primary.dark,
                filter: "brightness(90%)",
              },
              mt: 4,
              width: "fit-content",
            }}
          >
            <Typography
              color={colors.contrast}
              variant="button"
              textAlign="center"
            >
              Sekcja matur w budowie
            </Typography>
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        pr={{ xs: 0, lg: 4 }}
        pl={{ xs: 0, lg: 4 }}
        mt={isLargeScreen ? 6 : 4}
        order={isLargeScreen ? "initial" : 4}
      >
        <CardMedia component="img" image={leavingExamSectionPhoto} />
      </Grid>
    </Grid>
  );
};

export default LeavingExam;
