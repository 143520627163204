import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

interface NewRequirementFormProps {
  onAddRequirement: (requirement: { name: string; groupName: string }) => void;
}

const NewRequirementForm: React.FC<NewRequirementFormProps> = ({
  onAddRequirement,
}) => {
  const [name, setName] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onAddRequirement({ name, groupName });
    setName('');
    setGroupName('');
  };

  return (
    <form noValidate autoComplete='off'>
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField
          label='Name'
          variant='outlined'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label='Group Name'
          variant='outlined'
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <Button
          type='button'
          onClick={(event) => handleSubmit(event)}
          variant='contained'
          color='primary'
        >
          Add Requirement
        </Button>
      </Box>
    </form>
  );
};

export default NewRequirementForm;
