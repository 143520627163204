import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import { Search } from '@mui/icons-material';
import { debounce, IconButton, InputAdornment, TextField } from '@mui/material';
import colors from '../../config/colors';

interface SearchBarProps {
  searchedPhrase?: string | null;
  setSearchedPhrase?: Dispatch<React.SetStateAction<string | null>>;
}

const SearchBar = ({ searchedPhrase, setSearchedPhrase }: SearchBarProps) => {
  const [inputValue, setInputValue] = useState<string | null>(
    searchedPhrase || ''
  );

  const debouncedSetSearchedPhrase = useCallback(
    debounce((value: string) => {
      if (setSearchedPhrase) {
        setSearchedPhrase(value);
      }
    }, 500),
    []
  );

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSetSearchedPhrase(value);
  };

  // Update input value when searchedPhrase changes
  useEffect(() => {
    if (searchedPhrase !== inputValue) {
      setInputValue(searchedPhrase || '');
    }
  }, [searchedPhrase]);

  return (
    <form>
      <TextField
        id='search-bar'
        className='text'
        value={inputValue}
        onChange={handleInputChange}
        sx={{ maxWidth: '100%' }}
        variant='outlined'
        placeholder='Wpisz szukaną frazę...'
        size='medium'
        InputProps={{
          sx: {
            width: '360px',
            maxWidth: '100%',
            borderRadius: '20px',
            borderColor: colors.border,
            fontSize: '16px',
            paddingLeft: '8px',
            paddingRight: '24px',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='search'
                onClick={() => console.log('search')}
                edge='end'
              >
                <Search style={{ fill: colors.primary.dark }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchBar;
