import React from "react";
import {
  Button,
  Container,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import colors from "../../../config/colors";
import { Link } from "react-router-dom";

const Hero = () => {
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  return (
    <>
      <div className="homepage-root" />
      <Grid
        sx={{
          display: "flex",
          alignItems: isLargeScreen ? "flex-end" : "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
          width: "-webkit-fill-available",
          top: isSmallScreen ? "initial" : "50%",
          transform: isSmallScreen ? "initial" : "translateY(-50%)",
        }}
      >
        <Container
          sx={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            margin: 0,
            top: 0,
            position: "absolute",
            right: "15%",
          }}
        >
          <Typography
            variant="h4"
            color={colors.text.primary}
            sx={{
              position: "absolute",
              top: "25%",
              right: "25%",
              zIndex: 10,
            }}
            className="opaque"
          >
            Sprawdzian?
          </Typography>
          <Typography
            variant="h4"
            className="opaque"
            color={colors.text.primary}
            sx={{
              position: "absolute",
              top: "calc(15% + 80px)",
              right: "calc(25% - 120px)",
            }}
          >
            Egzamin?
          </Typography>
          <Typography
            variant="h4"
            className="opaque"
            color={colors.text.primary}
            sx={{
              position: "absolute",
              top: "calc(15% + 160px)",
              right: "calc(25% - 240px)",
            }}
          >
            Matura?
          </Typography>
        </Container>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isLargeScreen ? "flex-end" : "center",
            gap: isSmallScreen ? 10 : 4,
            mt: 10,
          }}
        >
          <Stack>
            <Typography
              variant="h1"
              component="h1"
              color={colors.text.primary}
              display="flex"
              flexDirection="column"
            >
              Przygotuj się do matury efektywnie.
              <Typography
                variant="h1"
                component="span"
                color={colors.text.primary}
                pl={isLargeScreen ? 12 : 0}
                mt={4}
                display="flex"
                flexDirection="column"
              >
                Rozwiązuj zadania z matematyki,
                <Typography
                  variant="h1"
                  component="span"
                  color={colors.text.primary}
                >
                  kiedy tylko masz czas.
                </Typography>
              </Typography>
            </Typography>
          </Stack>
          <Stack flexDirection={isSmallScreen ? "row" : "column"} gap="40px">
            <Button
              component={Link}
              to="/dzialy"
              variant="contained"
              sx={{
                background: colors.primary.main,
                padding: "18px 24px",
                minWidth: "210px",
                borderRadius: "16px",
                "&:hover": {
                  background: colors.primary.main,
                  filter: "brightness(90%)",
                },
              }}
            >
              <Typography color={colors.text.primary} variant="button">
                Rozwiązuj zadania
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                background: colors.primary.dark,
                padding: "18px 24px",
                minWidth: "210px",
                borderRadius: "16px",
                "&:hover": {
                  background: colors.primary.dark,
                  filter: "brightness(90%)",
                },
              }}
            >
              <Typography color={colors.contrast} variant="button">
                Oferta <br /> pojawi się wkrótce
              </Typography>
            </Button>
          </Stack>
        </Container>
      </Grid>
    </>
  );
};

export default Hero;
