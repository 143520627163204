import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  Chip,
  CircularProgress,
  Checkbox,
  IconButton,
  Autocomplete,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAddExampleMutation } from "../../../services/examples";
import { IAnswer, Requirement } from "../../../interfaces";
import {
  useAddRequirementMutation,
  useGetRequirementsQuery,
} from "../../../services/requirements";
import NewRequirementForm from "./NewRequirementForm";
import { BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
// Function to parse custom symbols to LaTeX
const parseToLatex = (input: string): string => {
  const replacements: { [key: string]: RegExp } = {
    pow: /pow\s*{([^}]*)}\s*{([^}]*)}/g, // Match pow with base and exponent
    alpha: /alpha/g,
    beta: /beta/g,
    delta: /delta/g,
    gamma: /gamma/g,
    sigma: /sigma/g,
  };

  let latex = input;

  // Apply replacements iteratively for square roots

  // Replace fractions, powers, and Greek letters
  latex = latex.replace(
    replacements.frac,
    (_, p1, p2) => `\\frac{${p1}}{${p2}}`
  );
  latex = latex.replace(replacements.pow, (_, p1, p2) => `${p1}^{${p2}}`);
  latex = latex.replace(replacements.alpha, "\\alpha");
  latex = latex.replace(replacements.beta, "\\beta");
  latex = latex.replace(replacements.delta, "\\delta");
  latex = latex.replace(replacements.gamma, "\\gamma");
  latex = latex.replace(replacements.sigma, "\\sigma");

  return latex;
};

interface IAddExampleFormProps {
  isOpen: boolean;
  onClose: () => void;
  exerciseGroupId: string;
  exerciseGroupType: string;
}

const AddExampleForm = ({
  isOpen,
  onClose,
  exerciseGroupId,
  exerciseGroupType,
}: IAddExampleFormProps) => {
  const [question, setQuestion] = useState("");
  const [parsedQuestion, setParsedQuestion] = useState("");
  const [answers, setAnswers] = useState<IAnswer[]>([
    { text: "", isCorrect: false },
  ]);
  const [attachment, setAttachment] = useState<File | null>(null);
  const [isLeavingExamExample, setIsLeavingExamExample] = useState(false);
  const [selectedRequirementIds, setSelectedRequirementIds] = useState<
    string[]
  >([]);
  const { data: allRequirements, isLoading: isLoadingRequirements } =
    useGetRequirementsQuery();
  const [addExample, { isLoading }] = useAddExampleMutation();
  const [addRequirement, { isLoading: isAdding }] = useAddRequirementMutation();

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setQuestion("");
    setParsedQuestion("");
    setAnswers([{ text: "", isCorrect: false }]);
    setAttachment(null);
    setIsLeavingExamExample(false);
    setSelectedRequirementIds([]);
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuestion(value);
    setParsedQuestion(parseToLatex(value)); // Parse question to LaTeX
  };

  const handleAddAnswer = () =>
    setAnswers([...answers, { text: "", isCorrect: false }]);

  const handleAnswerChange = (index: number, value: string) => {
    const newAnswers = answers.map((answer, idx) =>
      idx === index ? { ...answer, text: value } : answer
    );
    setAnswers(newAnswers);
  };

  const handleAnswerCorrectnessChange = (index: number) => {
    const newAnswers =
      exerciseGroupType === "ABCD"
        ? answers.map((answer, idx) => ({
            ...answer,
            isCorrect: idx === index,
          }))
        : answers.map((answer, idx) =>
            idx === index ? { ...answer, isCorrect: !answer.isCorrect } : answer
          );
    setAnswers(newAnswers);
  };

  const handleRemoveAnswer = (index: number) => {
    setAnswers(answers.filter((_, idx) => idx !== index));
  };

  const handleAttachmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttachment(event.target.files ? event.target.files[0] : null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const correctAnswers = answers
      .filter((answer) => answer.isCorrect)
      .map((answer) => answer.text);

    const formData = new FormData();
    formData.append("question", String(question));
    formData.append("exerciseGroupId", String(exerciseGroupId));
    formData.append("answers", JSON.stringify(answers));
    formData.append("correctAnswers", JSON.stringify(correctAnswers));
    formData.append("requirements", JSON.stringify(selectedRequirementIds));
    formData.append(
      "isLeavingExamExample",
      JSON.stringify(isLeavingExamExample)
    );

    if (attachment) {
      formData.append("attachment", attachment);
    }

    try {
      await addExample(formData).unwrap();
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error occurred while submitting the form:", error);
    }
  };

  const handleToggleRequirement = (value: Requirement[]) => {
    setSelectedRequirementIds(value.map((r) => r.id));
  };

  const handleAddRequirement = async (requirementData: {
    name: string;
    groupName: string;
  }) => {
    try {
      const newRequirement = await addRequirement(requirementData).unwrap();
      setSelectedRequirementIds((prevSelected) => [
        ...prevSelected,
        newRequirement.id,
      ]);
    } catch (error) {
      console.error("Error adding requirement:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>Dodaj nowy przykład</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Question"
            fullWidth
            variant="outlined"
            value={question}
            onChange={handleQuestionChange}
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" component="label">
              Wgraj zdjęcie
              <input type="file" hidden onChange={handleAttachmentChange} />
            </Button>
            {attachment && <Chip label={attachment.name} sx={{ mt: 1 }} />}
          </Box>
          {/* Preview LaTeX-rendered question */}
          {question && question.includes("\\") && (
            <Box mt={2}>
              <Typography variant="subtitle2">Preview LaTeX:</Typography>
              <BlockMath>{parsedQuestion}</BlockMath>
            </Box>
          )}
          {answers.map((answer, index) => (
            <Grid container spacing={1} alignItems="center" key={index}>
              <Grid item xs>
                <TextField
                  margin="dense"
                  label={`Answer ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  value={answer.text}
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                />
                {/* Preview LaTeX-rendered answer */}
                {answer.text && answer.text.includes("\\") && (
                  <Box mt={1}>
                    <Typography variant="subtitle2">Preview LaTex:</Typography>
                    <BlockMath>{parseToLatex(answer.text)}</BlockMath>
                  </Box>
                )}
              </Grid>
              <Grid item>
                <Checkbox
                  checked={answer.isCorrect}
                  onChange={() => handleAnswerCorrectnessChange(index)}
                  inputProps={{ "aria-label": "Select correct answer" }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => handleRemoveAnswer(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddAnswer}
          >
            Dodaj odpowiedź
          </Button>
          <Autocomplete
            sx={{ marginBottom: 2, marginTop: 2 }}
            multiple
            options={allRequirements || []}
            getOptionLabel={(option) => option.name}
            value={
              allRequirements?.filter((r) =>
                selectedRequirementIds.includes(r.id)
              ) || []
            }
            onChange={(event, newValue) => handleToggleRequirement(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Requirements"
                placeholder="Select Requirements"
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isLeavingExamExample}
                onChange={(e) => setIsLeavingExamExample(e.target.checked)}
                color="primary"
              />
            }
            label="Przykład maturalny"
          />
          <NewRequirementForm onAddRequirement={handleAddRequirement} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddExampleForm;
