import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Chip,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  ExerciseGroup,
  ExerciseType,
  UpdateExerciseGroupDto,
} from "../../../interfaces"; // Ensure ExerciseType is correctly imported
import { useGetExerciseTypesQuery } from "../../../services/exerciseTypes"; // Adjust based on your actual query hook

interface EditExerciseGroupFormProps {
  isOpen: boolean;
  onClose: () => void;
  group: ExerciseGroup | null;
  onSave: (group: ExerciseGroup) => Promise<void>;
}

const EditExerciseGroupForm: React.FC<EditExerciseGroupFormProps> = ({
  isOpen,
  onClose,
  group,
  onSave,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState<string | undefined>("");
  const [selectedExerciseType, setSelectedExerciseType] = useState<
    ExerciseType | undefined
  >(group?.exerciseType);
  const [isLeavingExamGroup, setIsLeavingExamGroup] = useState(
    group?.isLeavingExamGroup
  );

  const { data: exerciseTypes, isLoading: isLoadingExerciseTypes } =
    useGetExerciseTypesQuery();

  useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description);
      setSelectedExerciseType(group.exerciseType);
      setIsLeavingExamGroup(group.isLeavingExamGroup || false); // Initialize checkbox state
    }
  }, [group]);

  const handleSelectExerciseType = (type: ExerciseType) => {
    setSelectedExerciseType(type);
  };

  useEffect(() => {
    setIsLeavingExamGroup(group?.isLeavingExamGroup);
  }, [group]);

  const handleSubmit = async () => {
    if (!group) return;

    // Construct the updatedGroup object including name, exerciseType, and isLeavingExamGroup changes
    const updatedGroup: Partial<UpdateExerciseGroupDto> = {
      ...group,
      name: name,
      description: description,
      exerciseType: selectedExerciseType, // Assuming your onSave function or backend can handle exerciseTypeId correctly
      isLeavingExamGroup, // Include checkbox state in the update
    };

    await onSave(updatedGroup as ExerciseGroup); // Type assertion here; ensure onSave can handle this structure
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nazwa grupy zadań"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }} // Add margin below the TextField
        />
        <TextField
          autoFocus
          margin="dense"
          label="Opis grupy zadań"
          type="text"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2 }} // Add margin below the TextField
        />
        <Typography variant="subtitle1">Select Exercise Type:</Typography>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
          {isLoadingExerciseTypes ? (
            <Chip label="Loading types..." />
          ) : (
            exerciseTypes?.map((type: ExerciseType) => (
              <Chip
                key={type?.id}
                label={type.name}
                clickable
                color={
                  selectedExerciseType?.id === type.id ? "primary" : "default"
                }
                onClick={() => handleSelectExerciseType(type)}
              />
            ))
          )}
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLeavingExamGroup}
              onChange={(e) => setIsLeavingExamGroup(e.target.checked)}
              color="primary"
            />
          }
          label="Grupa zadań maturalnych"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={isLoadingExerciseTypes || !selectedExerciseType || !name}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExerciseGroupForm;
