import React from "react";
import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import abcdPhoto from "../../../assets/examples/abcd.webp";
import ownTypingPhoto from "../../../assets/examples/own_typing.webp";
import photoExercisePhoto from "../../../assets/examples/photo_exercise.webp";
import maturaPhoto from "../../../assets/examples/maturalne.webp";
import latexPhoto from "../../../assets/examples/latex.webp";
import FeatureCard from "./FeatureCard/FeatureCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Features = () => {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("md", "lg")
  );

  const features = [
    {
      photo: abcdPhoto,
      title: "Zadania typu ABCD",
      description: "Wybierz odpowiedź spośród podanych",
      photoOptions: { maxWidth: "300px" },
    },
    {
      photo: ownTypingPhoto,
      title: "Zadania otwarte",
      description: "Samodzielnie opracuj pełną odpowiedź",
    },
    {
      photo: photoExercisePhoto,
      title: "Zadania ze zdjęciem",
      description: "Rozwiązuj zadania z wizualnym wsparciem",
    },
    {
      photo: maturaPhoto,
      title: "Zadania maturalne",
      description: "Przygotuj się do zadań egzaminacyjnych",
      photoOptions: { maxWidth: "300px" },
    },
    {
      photo: latexPhoto,
      title: "Prawidłowy zapis",
      description: "Precyzyjny zapis matematyczny",
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    adaptiveHeight: true,
    slidesToShow: isLargeScreen ? 3 : isMediumScreen ? 2 : 1,
    slidesToScroll: 1,
    centerMode: !isLargeScreen, // Enable centerMode on small and medium screens
    centerPadding: !isLargeScreen ? "50px" : "0px", // Show more of the left and right items on small screens
  };

  return (
    <Grid
      container
      sx={{
        paddingX: { xs: 2, lg: 6 },
        mt: 10,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" textAlign="center">
          Examly to zróżnicowany zbiór zadań
        </Typography>
      </Grid>
      {isLargeScreen ? (
        <Grid container gap={4} mt={6} justifyContent="center">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              photo={feature.photo}
              photoOptions={feature.photoOptions}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} mt={6} justifyContent="center">
          <Slider {...sliderSettings}>
            {features.map((feature, index) => (
              <div key={index} style={{ padding: "0 15px" }}>
                <FeatureCard
                  photo={feature.photo}
                  photoOptions={feature.photoOptions}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </Slider>
        </Grid>
      )}
    </Grid>
  );
};

export default Features;
