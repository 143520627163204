import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import colors from "../../../config/colors";
import { ReactComponent as Logo } from "../../../assets/svg/logo.svg";

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const renderMenuItems = () => (
    <>
      <Button
        color="inherit"
        component={Link}
        to="/"
        sx={{ borderRadius: 0 }}
        onClick={handleMenuToggle} // Close menu after clicking
      >
        <Typography
          variant="h5"
          textTransform="initial"
          color={colors.text.primary}
        >
          Home
        </Typography>
      </Button>
      <Button
        color="inherit"
        component={Link}
        to="/dzialy"
        sx={{ borderRadius: 0 }}
        onClick={handleMenuToggle} // Close menu after clicking
      >
        <Typography
          variant="h5"
          textTransform="initial"
          color={colors.text.primary}
        >
          Zadania
        </Typography>
      </Button>
      {/* <Button
        color="inherit"
        component={Link}
        to="/o-autorach"
        sx={{ borderRadius: 0 }}
        onClick={handleMenuToggle} // Close menu after clicking
      >
        <Typography
          variant="h5"
          textTransform="initial"
          color={colors.text.primary}
        >
          Oferta
        </Typography>
      </Button> */}
    </>
  );

  return (
    <AppBar position="static" className="navbar-root">
      <Toolbar
        sx={{
          background: colors.background.secondary,
          display: "flex",
          justifyContent: "space-between",
          paddingY: "16px",
        }}
      >
        <Link to={"/"}>
          <Logo />
        </Link>
        {isSmDown ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuToggle}
              sx={{ svg: { fontSize: "2rem" } }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={menuOpen}
              onClose={handleMenuToggle}
              sx={{
                "& .MuiDrawer-paper": {
                  backgroundColor: colors.background.secondary,
                  width: 250,
                  padding: 2,
                },
              }}
            >
              <IconButton onClick={handleMenuToggle}>
                <CloseIcon />
              </IconButton>
              <Stack direction="column">{renderMenuItems()}</Stack>
            </Drawer>
          </>
        ) : (
          <Stack flexDirection="row">{renderMenuItems()}</Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
