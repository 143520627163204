import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Department } from "../interfaces";

export type Token = string | null;
export type Role = string | null;

export const departmentsApi = createApi({
  reducerPath: "departmentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Department"],
  endpoints: (builder) => ({
    getDepartments: builder.query<
      Department[],
      { departmentName?: string | null }
    >({
      query: ({ departmentName }) => {
        let queryString = "/departments";
        if (departmentName) {
          queryString += `?departmentName=${encodeURIComponent(departmentName)}`;
        }
        return queryString;
      },
      providesTags: ["Department"],
    }),
    getAllDepartments: builder.query<
      Department[],
      { departmentName?: string | null }
    >({
      query: ({ departmentName }) => {
        let queryString = "/departments/all";
        if (departmentName) {
          queryString += `?departmentName=${encodeURIComponent(departmentName)}`;
        }
        return queryString;
      },
      providesTags: ["Department"],
    }),
    addDepartment: builder.mutation<
      Department,
      { department: Partial<Department> }
    >({
      query: ({ department }) => ({
        url: "/departments",
        method: "POST",
        body: department,
      }),
      invalidatesTags: ["Department"],
    }),
    editDepartment: builder.mutation<
      Department,
      { id: string; data: Partial<Department> }
    >({
      query: ({ id, data }) => ({
        url: `/departments/${id}`,
        method: "PATCH", // Use 'PATCH' if you're partially updating the resource, 'PUT' if you're replacing it
        body: data,
      }),
      invalidatesTags: ["Department"],
    }),
    removeDepartment: builder.mutation<void, string>({
      query: (id) => ({
        url: `/departments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Department"],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetAllDepartmentsQuery,
  useLazyGetDepartmentsQuery,
  useAddDepartmentMutation,
  useEditDepartmentMutation,
  useRemoveDepartmentMutation,
} = departmentsApi;
