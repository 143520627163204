import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { departmentsApi } from "../services/departments";
import { topicsApi } from "../services/topics";
import { exercisesApi } from "../services/exercises";
import { exerciseTypesApi } from "../services/exerciseTypes";
import { examplesApi } from "../services/examples";
import { requirementsApi } from "../services/requirements";
import { requirementGroupsApi } from "../services/requirementGroups";
import { authApi } from "../services/auth";

export const store = configureStore({
  reducer: {
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    [exercisesApi.reducerPath]: exercisesApi.reducer,
    [exerciseTypesApi.reducerPath]: exerciseTypesApi.reducer,
    [examplesApi.reducerPath]: examplesApi.reducer,
    [requirementsApi.reducerPath]: requirementsApi.reducer,
    [requirementGroupsApi.reducerPath]: requirementGroupsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(departmentsApi.middleware)
      .concat(topicsApi.middleware)
      .concat(exercisesApi.middleware)
      .concat(exerciseTypesApi.middleware)
      .concat(examplesApi.middleware)
      .concat(requirementsApi.middleware)
      .concat(requirementGroupsApi.middleware)
      .concat(authApi.middleware),
});

setupListeners(store.dispatch);
