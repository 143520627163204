import React from 'react';
import { useAddExerciseTypeMutation } from '../../../services/exerciseTypes';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, CircularProgress, Alert } from '@mui/material';

interface IFormInput {
  exerciseTypeName: string;
}

interface Props {
  onSuccess: () => void; // Callback for successful submission
}

const AddExerciseTypeForm: React.FC<Props> = ({ onSuccess }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const [addExerciseType, { isLoading, isSuccess, isError, error }] =
    useAddExerciseTypeMutation();

  const onSubmit = async (data: IFormInput) => {
    try {
      await addExerciseType({ name: data.exerciseTypeName }).unwrap(); // Ensure to call unwrap() to handle errors correctly
      reset();
      onSuccess(); // Invoke the onSuccess callback on successful submission
    } catch (e) {
      // Error handling is managed by RTK Query's isError state
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display='flex' flexDirection='column' gap={2} maxWidth={400}>
        <TextField
          {...register('exerciseTypeName', {
            required: 'To pole jest wymagane',
          })}
          label='Exercise Type Name'
          variant='outlined'
          error={!!errors.exerciseTypeName}
          helperText={errors.exerciseTypeName?.message}
          disabled={isLoading}
        />
        <Button type='submit' variant='contained' disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Add Exercise Type'}
        </Button>
        {isSuccess && (
          <Alert severity='success'>Exercise type added successfully!</Alert>
        )}
        {isError && (
          <Alert severity='error'>
            Error adding exercise type: {error?.toString()}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default AddExerciseTypeForm;
