import React from "react";
import Navbar from "../../components/common/Navbar/Navbar";
import "./styles.scss";
import { Helmet } from "react-helmet";
import Hero from "./Hero/Hero";
import LeavingExam from "./LeavingExam/LeavingExam";
import Features from "./Features/Features";
import Footer from "../../components/common/Footer/Footer";

const HomePage: React.FC = () => {
  return (
    <>
      <div className="hero-container">
        <Helmet defer={false}>
          <title>{"Examly"}</title>
          <meta
            name="description"
            content="Przygotuj się do matury z zadaniami i materiałami edukacyjnymi przygotowanymi przez Examly. Egzamin maturalny bez stresu dzięki skutecznym metodom przygotowania i różnorodnej formule pytań.
 "
          />
          <link rel="canonical" href={`https://examly.eu`} />
        </Helmet>
        <Navbar />
        <Hero />
      </div>
      <Features />
      <LeavingExam />
      <Footer />
    </>
  );
};

export default HomePage;
