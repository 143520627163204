import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Container,
  Stack,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar/Navbar";
import "./styles.scss";
import { Box } from "@mui/material";
import colors from "../../config/colors";
import DepartmentCard from "../../components/DepartmentCard/DepartmentCard";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useLazyGetTopicsQuery } from "../../services/topics";
import TopicCard from "../../components/TopicCard/TopicCard";
import { ArrowBack } from "@mui/icons-material";
import { Helmet } from "react-helmet";

export default function TopicsPage() {
  const [searchedPhrase, setSearchedPhrase] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const departmentId = location.state?.departmentId;
  const [trigger, { data, isLoading, error }] = useLazyGetTopicsQuery();
  const departmentName = location.state?.departmentName;

  useEffect(() => {
    if (departmentId !== undefined) {
      trigger({ departmentId, topicName: searchedPhrase });
    }
  }, [departmentId, searchedPhrase, trigger]);

  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  return (
    <Box>
      <Helmet>
        <title>{"Examly | Tematy"}</title>
        <meta
          name="description"
          content="Opanuj działania na przedziałach, logarytmy, pierwiastki, potęgi i wiele więcej. Skorzystaj z tematycznie podzielonej bazy zadań"
        />
        <link
          rel="canonical"
          href={`https://examly.eu/tematy/${departmentName}`}
        />
      </Helmet>
      <Navbar />
      <Container sx={{ textAlign: "center", mt: 10, mb: 10 }}>
        <Typography variant="h1" color={colors.text.primary}>
          Jaki temat chcesz poruszyć?
        </Typography>
        <Stack mt={4}>
          <SearchBar
            searchedPhrase={searchedPhrase}
            setSearchedPhrase={setSearchedPhrase}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent={isMediumScreen ? "initial" : "center"}
            mt={4}
            position="relative"
            px={6}
          >
            <Typography
              ml={2}
              variant="h4"
              color={colors.text.primary}
              textAlign="left"
              position="relative"
              display="flex"
              alignItems="center"
            >
              {data?.[0]?.department?.name && "Dział: "}
              {data?.[0]?.department?.name}

              <IconButton
                aria-label="add an alarm"
                onClick={() => navigate(-1)}
                size="large"
                sx={{
                  position: "absolute",
                  left: "-58px",
                }}
              >
                <ArrowBack sx={{ stroke: colors.border, fontSize: "28px" }} />
              </IconButton>
            </Typography>
          </Stack>
          <Grid
            container
            xs={12}
            mt={4}
            flexDirection="row"
            justifyContent="center"
            gap={8}
            flexWrap="wrap"
          >
            {data?.map((topic) => {
              return <TopicCard topic={topic} key={topic.id} />;
            })}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
