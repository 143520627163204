import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RequirementGroup } from "../interfaces"; // Adjust the import path as needed

export const requirementGroupsApi = createApi({
  reducerPath: "requirementGroupsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["RequirementGroup"],
  endpoints: (builder) => ({
    getRequirementGroups: builder.query<RequirementGroup[], void>({
      query: () => "/requirement-categories",
      providesTags: ["RequirementGroup"],
    }),
    addRequirementGroup: builder.mutation<
      RequirementGroup,
      Partial<RequirementGroup>
    >({
      query: (newRequirementGroup) => ({
        url: "/requirement-categories",
        method: "POST",
        body: newRequirementGroup,
      }),
      invalidatesTags: ["RequirementGroup"],
    }),
    editRequirementGroup: builder.mutation<
      RequirementGroup,
      { id: string; data: Partial<RequirementGroup> }
    >({
      query: ({ id, data }) => ({
        url: `/requirement-categories/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["RequirementGroup"],
    }),
    deleteRequirementGroup: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query: (id) => ({
        url: `/requirement-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RequirementGroup"],
    }),
  }),
});

export const {
  useGetRequirementGroupsQuery,
  useAddRequirementGroupMutation,
  useEditRequirementGroupMutation,
  useDeleteRequirementGroupMutation, // Include this if implementing deletion functionality
} = requirementGroupsApi;
