import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateExerciseTypeDto, ExerciseType } from "../interfaces";
import { EexerciseType } from "../enums";

export const exerciseTypesApi = createApi({
  reducerPath: "exerciseTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["ExerciseType"],
  endpoints: (builder) => ({
    getExerciseTypes: builder.query<ExerciseType[], void>({
      query: () => "/exercise-types",
      providesTags: ["ExerciseType"],
    }),
    addExerciseType: builder.mutation<
      ExerciseType,
      Partial<CreateExerciseTypeDto>
    >({
      query: (newExerciseType) => ({
        url: "/exercise-types",
        method: "POST",
        body: newExerciseType,
      }),
      invalidatesTags: ["ExerciseType"],
    }),
    editExerciseType: builder.mutation<
      ExerciseType,
      { id: EexerciseType; data: Partial<ExerciseType> }
    >({
      query: ({ id, data }) => ({
        url: `/exercise-types/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["ExerciseType"],
    }),
    removeExerciseType: builder.mutation<
      { success: boolean; id: number },
      EexerciseType
    >({
      query: (id) => ({
        url: `/exercise-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExerciseType"],
    }),
    // You can add more endpoints as needed
  }),
});

export const {
  useGetExerciseTypesQuery,
  useAddExerciseTypeMutation,
  useEditExerciseTypeMutation,
  useRemoveExerciseTypeMutation, // Export the new hook
} = exerciseTypesApi;
